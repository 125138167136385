<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" class="myresource">
    <!-- 试题 -->
    <myQuestion
      v-if="currentTypeId == 0"
      :key="newKeys"
      :resourse-type-data="interactiveData"
      :resource-type-list="resourceTypeList"
    ></myQuestion>
    <!-- 试卷 -->
    <MyPaper
      v-if="currentTypeId == 1"
      :key="newKeys"
      :resourse-type-data="interactiveData"
      :resource-type-list="resourceTypeList"
    ></MyPaper>
    <!-- 教材 -->
    <MyTeachingMaterial
      v-if="currentTypeId == 2"
      :key="newKeys"
      :resourse-type-data="interactiveData"
      :resource-type-list="resourceTypeList"
    ></MyTeachingMaterial>
    <!-- 教案|课件 -->
    <MyTeachingPlan
      v-if="currentTypeId == 555"
      :key="newKeys"
      :resourse-type-data="interactiveData"
    ></MyTeachingPlan>

    <!-- 微课 -->
    <MyMicroLectures
      v-if="currentTypeId == 555"
      :key="newKeys"
      :resourse-type-data="interactiveData"
    ></MyMicroLectures>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getStore, removeStore } from "@/core/util/store";
import MyPaper from "./components/myPaper";
import MyTeachingPlan from "@/views/resource/myResource/myTeachingPlan";
import MyTeachingMaterial from "./components/myTeachingMaterial";
import MyMicroLectures from "@/views/resource/myResource/myMicroLessons";
import myQuestion from "./components/myQuestion";
const resourceTypeList = [
  { value: 0, label: "试题", type: true },
  { value: 1, label: "试卷" },
  { value: 2, label: "教材" },
];
const resourceTypeListOne = [
  // { value: 0, label: "试题" },
  { value: 1, label: "试卷" },
  { value: 2, label: "教材" },
];
let menuList = [
  {
    name: "我的收藏",
    type: 1,
    isClick: true,
    img: require("@/assets/resourse/paper/icon_collect.png"),
  },
  {
    name: "我的组卷",
    type: 2,
    isClick: false,
    img: require("@/assets/resourse/paper/icon_paper.png"),
  },
  {
    name: "我的上传",
    type: 3,
    isClick: false,
    img: require("@/assets/resourse/paper/icon_my_up.png"),
  },
  {
    name: "我的作业卡",
    type: 4,
    isClick: false,
    img: require("@/static/homework/my homework card_icon16@1x.png"),
  },
];

export default {
  name: "Myresource",
  components: {
    MyPaper,
    MyTeachingPlan,
    MyTeachingMaterial,
    MyMicroLectures,
    myQuestion,
  },
  props: {
    resourseTypeData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      resourceTypeList: resourceTypeList,
      resourceTypeListOne: resourceTypeListOne,
      fullscreenLoading: false,
      newKeys: 0,
      //tab
      currentTypeId: 0, //0-试卷 1-教案 2-教材 3-微课
      interactiveData: this.resourseTypeData, //tab切换时传输的数据
      menuList: [],
      // 搜索
      searchItems: {
        level: "",
        resGradeId: 0,
        subjectId: "",
        keyword: "",
        startTime: "",
        endTime: "",
        beforeYear: 0,
        year: 0,
        rType: 1,
        type: 0,
        size: 12,
        current: 1,
      },
    };
  },
  computed: {
    ...mapGetters({ getLevelId: "getLevelId", getSubjectId: "getSubjectId" }),
  },
  created() {
    this.getBaseData();
    const storage = localStorage.getItem("jzjx-schoolInfo");
    const schoolInfo = storage ? JSON.parse(storage).content : {};
    const application = schoolInfo.application || "";
    const hasHomework = application.indexOf("221") != -1;
    this.menuList = menuList;
    if (!hasHomework) {
      this.menuList = menuList.filter((item) => item.name != "我的作业卡");
    }
  },
  methods: {
    getBaseData() {
      if (this.$route.query.type) {
        // 从我的作业卡跳转过来
        this.menuList.map((menuItem) => {
          menuItem.isClick = false;
          if (this.$route.query.type == menuItem.type) {
            menuItem.isClick = true;
            this.searchItems.current = 1;
          }
        });
        this.changeResourceType(1, this.searchItems, false);
      } else {
        let tab_data = getStore({ name: "myResourceSearchItems" });
        removeStore({ name: "" });

        if (tab_data) {
          // let currentType = tab_data.search_items.paperType;
          // if (this.currentTypeId == 4) {
          //   currentType = tab_data.search_items.lessonType;
          // }
          // this.searchItems.type = currentType;
          // this.searchItems = Object.assign(
          //   this.searchItems,
          //   tab_data.search_items
          // );
          this.changeResourceType(tab_data.tab_id, this.searchItems, false);
        }
      }
    },
    changeResourceType(type, items, tab_click) {
      //type:0-试卷 1-教案 2-教材 3-微课
      // console.log("index..type=", type, ",currentTypeId=", this.currentTypeId);

      if (
        type != this.currentTypeId ||
        (type == this.currentTypeId && !tab_click)
      ) {
        this.searchItems = {};

        //wh:资源部分各TAB之间保持数据互通，其中教案|课件接口公用，使用字段type区分，与其他tab的筛选条件(类型:type)冲突，tab切换时单独处理为paperType(试卷类型)和lessonType(微课类型)进行传输
        // if (type > 0 && this.currentTypeId == 0) {
        //   this.searchItems.paperType = items.type;
        // }
        // if (type == 0) {
        //   this.searchItems.type = items.paperType;
        // }
        // if (type < 3 && this.currentTypeId == 3) {
        //   this.searchItems.lessonType = items.type;
        // }
        // if (type == 3) {
        //   this.searchItems.type = items.lessonType;
        // }

        this.currentTypeId = type;
        this.interactiveData = {
          type: this.currentTypeId,
          searchItems: this.searchItems,
        };
        this.newKeys = new Date().getTime();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.myresource {
  // margin-left: calc(100vw - 100%); //避免页面有滚动条时整体的跳动
}
</style>
