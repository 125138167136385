<!-- 我的资源-教案|课件(由试卷部分拷贝，试卷字样都按教案|课件处理) -->
<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" class="my-teaching-plan">
    <div class="left-box">
      <el-button
        type="primary"
        class="btn-all"
        style="width: 246px"
        @click="uploadExamPaperDialog"
      >
        {{ currentTypeId === 1 ? " 上传教案 " : "上传课件"
        }}<i class="el-icon-upload el-icon--right"></i>
      </el-button>
      <div class="menu">
        <div v-for="(menu, index) in menuList" :key="index" class="menu-list">
          <div @click="menuClick(menu)">
            <img :src="menu.img" alt="" />
            <span :class="{ on: menu.isClick }">{{ menu.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="right-box">
      <div class="search-box">
        <div class="search-item">
          <div class="title">年级：</div>
          <template v-for="(v, i) in gradeOptionsCopy">
            <p
              :key="i"
              :class="{ on: v.isClick }"
              @click="setItem(v, gradeOptionsCopy)"
            >
              {{ v.label }}
            </p>
          </template>
        </div>
        <div class="search-item">
          <div class="title">类型：</div>
          <template v-for="(v, i) in teachingPlanType">
            <p
              :key="i"
              :class="{ on: v.isClick }"
              @click="setItem(v, teachingPlanType)"
            >
              {{ v.label }}
            </p>
          </template>
        </div>
        <div class="search-item search-item-old">
          <div>
            收藏日期:
            <el-date-picker
              v-model="dateValue"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="searchList"
            >
            </el-date-picker>
          </div>
          <el-input
            v-model="searchItems.keyword"
            placeholder="请输入内容"
            class="input-with-select"
            @keyup.enter="searchList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="searchList"
            ></el-button>
          </el-input>
        </div>
      </div>
      <!-- 类别tab -->
      <div class="resource-tab">
        <div
          v-for="(item, key) in resourceTypeList"
          :key="key"
          class="tab-item"
          :class="{ active: currentTypeId == key }"
          @click="changeTab(key, searchItems)"
        >
          <div class="type-name">{{ item }}</div>
          <div v-if="currentTypeId == key" class="underline"></div>
        </div>
      </div>
      <div class="list-box">
        <div class="list-title"></div>
        <div v-for="(v, i) in pageData" :key="i" class="item-list">
          <!-- 缩略图 -->
          <div class="thumbnail">
            <div class="count">{{ v.countImg }}p</div>
          </div>
          <div class="info">
            <div class="base-info">
              <div class="info">
                <span class="name" @click="toDetail(v.id)">{{ v.name }}</span>
                <div class="icon">
                  <template v-if="v.isStar == 1">
                    <i class="el-icon-star-on" @click="cancelFavorite(v)"></i>
                  </template>
                  <template v-else>
                    <i class="el-icon-star-off" @click="addFavoriteList(v)"></i>
                  </template>
                </div>
              </div>
              <div class="btn" @click="deleteTeachingPlan(v)">
                <i class="el-icon-close"></i>
              </div>
            </div>
            <div class="more-info">
              <div class="plan-data">
                <span class="data-item"> 阅读量：{{ v.countView }} </span>
                <span class="data-item"> 下载量：{{ v.countDl }} </span>
                <span class="data-item">上传时间：{{ v.createTime }}</span>
              </div>
              <div>
                <div class="btn-all" @click="downloadTeachingPlan(v)">
                  下载教案
                  <img src="@/static/resources/download.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="pageData.length == 0" class="none-list">
          <img src="@/static/resources/none.svg" alt="" />
          <p>暂未找到数据</p>
        </div>
        <div v-if="total != 0" class="list-box-bottom">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            @current-change="pageList"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!--上传弹窗-->
    <el-dialog
      class="upload-exam-paper-dialog"
      :title="currentTypeId === 1 ? '上传教案' : '上传课件'"
      :visible.sync="uploadDialogVisible"
      width="856px"
      :close-on-click-modal="false"
      :lock-scroll="true"
      @close="closeUploadDialog"
    >
      <template v-if="uploadStep === 1">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-width="100px"
          class="demo-ruleForm"
        >
          <div class="form-item-box">
            <el-form-item label="名称" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
          </div>
          <div class="form-item-box two-box">
            <el-form-item label="学段" prop="level">
              <el-select
                v-model="ruleForm.level"
                placeholder="请选择学段"
                @change="changeLevel()"
              >
                <el-option
                  v-for="item in levelOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="年级" prop="resGradeId">
              <el-select
                v-if="gradeOptions && gradeOptions.length > 0"
                v-model="ruleForm.resGradeId"
                placeholder="请选择年级"
                clearable
              >
                <el-option
                  v-for="item in gradeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="form-item-box two-box">
            <el-form-item label="科目" prop="subjectId">
              <el-select v-model="ruleForm.subjectId" placeholder="请选择科目">
                <el-option
                  v-for="item in subjectOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="类型" prop="type">
              <el-select v-model="ruleForm.type" placeholder="请选择类型">
                <el-option
                  v-for="item in examTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item> -->
          </div>
          <div class="form-item-box two-box">
            <el-form-item label="省" prop="province">
              <el-select
                v-model="ruleForm.province"
                placeholder="请选择省"
                @change="changeProvince"
              >
                <el-option
                  v-for="item in provinceOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="市" prop="city">
              <el-select
                v-model="ruleForm.city"
                placeholder="请选择市"
                @change="changeCity"
              >
                <el-option
                  v-for="item in cityOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="form-item-box two-box">
            <el-form-item label="区" prop="district">
              <el-select v-model="ruleForm.district" placeholder="请选择区">
                <el-option
                  v-for="item in districtOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="年份" prop="type">
              <el-date-picker
                v-model="ruleForm.year"
                type="year"
                placeholder="请选择年份"
                value-format="yyyy"
              >
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="form-item-box two-box">
            <el-form-item label="分享至" prop="share">
              <el-select v-model="ruleForm.share" placeholder="请选择">
                <el-option
                  v-for="item in shareOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
        <!-- <div style="color: #2474ED;cursor: pointer;" @click="gotoUploadStep2">
          选择知识点/章节
        </div> -->
        <span slot="footer" class="dialog-footer">
          <el-button class="confirmButton" @click="gotoUploadStep2"
            >下一步</el-button
          >
        </span>
      </template>
      <template v-if="uploadStep === 2">
        <span>选择知识点</span>
        <!-- 暂时不做章节 -->
        <!-- <div class="upload-teaching-plan-dialog-header">
          <div
            class="filetype"
            :class="{ 'selected-filetype': uploadTeachingPlanFilePoints === 1 }"
            @click="changeUploadTeachingPlanFileType(1)"
          >
            按知识点
          </div>
          <div
            class="filetype"
            :class="{ 'selected-filetype': uploadTeachingPlanFilePoints === 2 }"
            @click="changeUploadTeachingPlanFileType(2)"
          >
            按章节
          </div>
        </div> -->
        <div class="upload-teaching-plan-dialog-body">
          <div v-if="knowledgeContentType == 1" class="by-points">
            <div>
              <el-tree
                ref="tree"
                :key="newKeys"
                :data="knowledgeData"
                show-checkbox
                node-key="id"
                :default-checked-keys="loreTureArrid"
                :default-expanded-keys="defaultExpendedKeys"
                :props="defaultProps"
                @check="elTreeChange"
              >
              </el-tree>
            </div>
            <div style="width: 300px">
              <div class="search-item">
                <el-input
                  v-model="pointInput"
                  class="input-with-select"
                  @keyup.enter.native="searchLore"
                ></el-input>
                <div class="btn" @click="searchLore">搜索</div>
              </div>
              <el-checkbox-group
                v-model="loreTureArrid"
                style="width: 200px; margin-top: 20px"
              >
                <template v-for="(v, i) in TreeArray">
                  <el-checkbox :key="i" :label="v.id">{{ v.name }}</el-checkbox>
                </template>
              </el-checkbox-group>
            </div>
          </div>
          <!-- <div v-if="knowledgeContentType == 2" class="by-chapters">
            <div class="books">
              <span style="font-weight:700;">教材</span>
              <el-select
                v-model="seletedBook"
                placeholder="请选择"
                clearable=""
              >
                <el-option
                  v-for="item in textbooksArr"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div> -->
        </div>
        <span slot="footer" class="dialog-footer">
          <span class="left">
            <el-button class="cancelButton" @click="uploadStep = 1">
              上一步
            </el-button>
          </span>
          <span class="right">
            <el-button class="confirmButton" @click="gotoUploadStep3">
              下一步
            </el-button>
          </span>
          <div class="clear-both"></div>
        </span>
      </template>
      <template v-else-if="uploadStep === 3">
        <div class="upload-teaching-plan-dialog-header">
          <div v-if="currentTypeId == 1">
            <div
              class="filetype"
              :class="{ 'selected-filetype': uploadTeachingPlanFileType === 1 }"
              @click="changeUploadExamPaperFileType(1)"
            >
              Word文件上传
            </div>
            <div
              class="filetype"
              :class="{ 'selected-filetype': uploadTeachingPlanFileType === 2 }"
              @click="changeUploadExamPaperFileType(2)"
            >
              PDF文件上传
            </div>
            <div
              class="filetype"
              :class="{ 'selected-filetype': uploadTeachingPlanFileType === 3 }"
              @click="changeUploadExamPaperFileType(3)"
            >
              图片文件批量上传
            </div>
            <div
              class="filetype"
              :class="{ 'selected-filetype': uploadTeachingPlanFileType === 4 }"
              @click="changeUploadExamPaperFileType(4)"
            >
              PPT文件上传
            </div>
          </div>
        </div>
        <el-upload
          ref="uploadExamPaper"
          class="upload-button"
          drag
          :action="uploadExamPaperURL"
          :headers="uploadExamPaperHeaders"
          :accept="uploadExamPaperAccept"
          :multiple="uploadExamPaperMultiple"
          :limit="uploadExamPaperLimit"
          :file-list="uploadExamPaperFileListEle"
          :auto-upload="false"
          :on-change="uploadExamPaperOnChange"
          :before-remove="uploadExamPaperBeforeRemove"
          :on-exceed="uploadExamPaperOnExceed"
          :http-request="uploadExamPaperHttpRequest"
        >
          <div class="upload-text">
            将{{
              uploadTeachingPlanFileType === 1
                ? " Word "
                : uploadTeachingPlanFileType === 2
                ? " PDF "
                : uploadTeachingPlanFileType === 3
                ? "图片"
                : "PPT"
            }}
            文件拖放到此处或<br />点击上传
          </div>
        </el-upload>
        <span slot="footer" class="dialog-footer">
          <span class="left">
            <el-button class="cancelButton" @click="uploadStep = 2">
              上一步
            </el-button>
          </span>
          <span class="right">
            <el-button
              class="confirmButton"
              type="primary"
              :loading="loading"
              @click="uploadExamPaper"
            >
              上 传
            </el-button>
          </span>
          <div class="clear-both"></div>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  examTypeOptions,
  levelOptions,
  gradeHighOptions,
  gradeMiddleOptions,
  subjectOptions,
  gradeOptions,
} from "@/core/util/constdata";
import {
  getProvinceList,
  getCityList,
  getDistrictList,
} from "@/core/api/cms/geo";
import { getObj } from "@/core/api/school/school";
import { setStore, getStore } from "@/core/util/store";
import {
  getMyteachingPlan,
  unfavTeachingPlan,
  favTeachingPlan,
  getTeachingBooksList,
  uploadTeachingPlan,
  updateViewCount,
  updateDownloadCount,
  delTeachingPlan,
} from "@/core/api/resource/myResource";
import { getExamPaperImgZip } from "@/core/api/resource/exampaper";
import { getQuestionPoint } from "@/core/api/exam/examLore";
const menuList = [
  {
    name: "我的全部",
    type: 1,
    isClick: true,
    img: require("@/static/resources/group.png"),
  },
  {
    name: "我的收藏",
    type: 2,
    isClick: false,
    img: require("@/static/resources/collect.png"),
  },
  {
    name: "我的上传",
    type: 3,
    isClick: false,
    img: require("@/static/resources/up.png"),
  },
];
const teachingPlanType = [
  {
    isClick: true,
    label: "全部",
    value: 0,
  },
  {
    isClick: true,
    label: "word",
    value: 1,
  },
  {
    isClick: true,
    label: "pdf",
    value: 2,
  },
  {
    isClick: true,
    label: "图片",
    value: 3,
  },
  {
    isClick: true,
    label: "ppt",
    value: 4,
  },
  {
    isClick: true,
    label: "excel",
    value: 5,
  },
];
const resourceTypeList = ["试卷", "教案", "教材", "微课"];
export default {
  name: "Myresource",
  components: {},
  props: {
    resourseTypeData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      //知识点
      defaultProps: {
        children: "child",
        label: "name",
      },
      newKeys: new Date().getTime(),
      knowledgeData: [], //页面展示用所有知识点(树形结构)
      loreArr: [], //所有知识点的数组
      loreTureArr: [], // 选中的知识点的数组
      loreTureArrid: [], //默认勾选的节点的 key 的数组
      loreTureArrName: [],
      defaultExpendedKeys: [], //默认展开的节点的 key 的数组
      pointInput: "", //知识点搜索
      TreeArray: [], //搜索结果
      ajaxLoreIds: [],
      //章节
      textbooksArr: [], //所有教材
      seletedBook: "", //选择教材
      chaptersArr: [], //所选教材所有章节

      fullscreenLoading: false,
      modelKey: 1,
      listId: 0,
      activeName: "first",
      loading: false, // 载入
      menuList: menuList,
      //tab
      currentTypeId: 1, //0-试卷 1-教案 2-教材 3-微课
      resourceTypeList: resourceTypeList,
      interactiveData: this.resourseTypeData, //tab切换时传输的数据
      // option数据
      examTypeOptions: examTypeOptions,
      teachingPlanType: teachingPlanType,
      gradeHighOptions: gradeHighOptions,
      gradeMiddleOptions: gradeMiddleOptions,
      gradeOptions: gradeOptions,
      subjectOptions: subjectOptions,
      levelOptions: levelOptions(),
      provinceOptions: [],
      cityOptions: [],
      districtOptions: [],
      shareOptions: [
        { value: 0, label: "精品校辅" },
        { value: 1, label: "校本校辅" },
        { value: 2, label: "不分享" },
      ],
      showSearch: false,
      // 弹出层
      uploadDialogVisible: false,
      uploadStep: 1, //1-设置属性 2-选择知识点 3-上传文件
      uploadExamPaperURL: " /resource/teachassistrelation/my/upload",
      uploadExamPaperHeaders: {
        Authorization: "Bearer " + getStore({ name: "access_token" }),
      },
      uploadTeachingPlanFilePoints: 1, //上传教案知识点：1-知识点 2-章节
      knowledgeContentType: 1, //1-知识点 2-章节
      uploadTeachingPlanFileType: 1, // 上传教案类型 1-Word文件 2-PDF文件 3-图片文件批量
      uploadExamPaperFileData: new FormData(),
      uploadExamPaperFileList: [],
      uploadExamPaperAccept: this.currentTypeId == 1 ? ".docx" : ".ppt,.pptx",
      uploadExamPaperMultiple: false,
      uploadExamPaperLimit: 1,
      uploadExamPaperFileListEle: [],
      ruleForm: {
        name: "",
        level: "",
        resGradeId: "",
        subjectId: "",
        type: "",
        province: "", // 保存的行政区划代码
        city: "", // 保存的行政区划代码
        district: "", // 保存的行政区划代码
        year: "",
        share: 0,
      },
      // ruleForm初始值
      ruleFormReset: {
        name: "",
        level: "",
        resGradeId: "",
        subjectId: "",
        type: "",
        province: "", // 保存的行政区划代码
        city: "", // 保存的行政区划代码
        district: "", // 保存的行政区划代码
        year: "",
        share: 0,
      },
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        level: [{ required: true, message: "请选择学段", trigger: "change" }],
        resGradeId: [
          { required: true, message: "请选择年级", trigger: "change" },
        ],
        subjectId: [
          { required: true, message: "请选择科目", trigger: "change" },
        ],
        // type: [{ required: true, message: "请选择类型", trigger: "change" }],
        province: [{ required: true, message: "请选择省", trigger: "change" }],
        city: [{ required: true, message: "请选择市", trigger: "change" }],
        district: [{ required: true, message: "请选择区", trigger: "change" }],
        year: [{ required: true, message: "请选择年份", trigger: "change" }],
        share: [{ required: true, message: "请选择", trigger: "change" }],
      },

      // 搜索
      searchItems: {
        pageType: 1, //1-我的全部 2-我的收藏 3-我的上传
        type: 0, //1-教案 2-课件
        level: "",
        subjectId: "",
        resGradeId: 0,
        fileType: 0, //教辅文件类型 1-word 2-pdf 3-图片 4-ppt 5-excel
        startTime: "",
        endTime: "",
        keyword: "",
        size: 12,
        current: 1,
        rType: 1,
      },
      pageIndex: 1,
      pageData: [],
      gradeOptionsCopy: [],
      dateValue: [],
      total: 0,
      currentExamPaper: {},
      CDN: "https://static.wtjy.com/",
    };
  },
  computed: {
    ...mapGetters({ getLevelId: "getLevelId", getSubjectId: "getSubjectId" }),
  },
  watch: {
    loreTureArrid() {
      this.newKeys = new Date().getTime();
      this.defaultExpendedKeys = this.loreTureArrid;
      this.loreTureArrName = [];
      this.ajaxLoreIds = [];
      this.loreArr.forEach((i) => {
        if (this.loreTureArrid.indexOf(i.id) != -1 && i.child.length == 0) {
          this.loreTureArrName.push(i.name);
          this.ajaxLoreIds.push(i.number);
        }
        if (this.loreTureArrid.indexOf(i.id) != -1 && i.child.length > 0) {
          this.loreTureArrid.splice(this.loreTureArrid.indexOf(i.id), 1);
        }
      });
    },
    dateValue() {
      if (this.dateValue && this.dateValue.length > 0) {
        this.searchItems.startTime = this.dateValue[0];
        this.searchItems.endTime = this.dateValue[1];
      }
    },
  },
  created() {
    this.resetBasePageData();
    this.onloadList();
    this.getList();
    this.getProvinceList();
    window.addEventListener("keydown", this.handleKeyDown, true);
  },
  destroyed() {
    window.removeEventListener("keydown", this.handleKeyDown, true);
  },
  methods: {
    resetBasePageData() {
      if (this.interactiveData) {
        this.currentTypeId = this.interactiveData.type;
        this.searchItems = Object.assign(
          this.searchItems,
          this.interactiveData.searchItems
        );
      }
      this.dateValue = [this.searchItems.startTime, this.searchItems.endTime];
    },
    //知识点
    getKnowledgeData() {
      getQuestionPoint({
        level: this.getLevelId,
        subjectId: this.ruleForm.subjectId,
      }).then((res) => {
        this.loreArr = res.data.data.resQuestionPoints;
        let arrList = this.loreArr;
        arrList
          .filter((i) => i.rank == 1)
          .forEach((i) => {
            let obj = i;
            obj.child = this.convertToTreeData(arrList, i.number);
            this.knowledgeData.push(obj);
          });
      });
    },
    //递归形成树形结构
    convertToTreeData(data, pid) {
      const result = [];
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].number.slice(0, -1) == pid) {
          const obj = data[i];
          if (!obj.child) {
            obj.child = new Array();
          }
          temp = this.convertToTreeData(data, data[i].number);
          if (temp.length > 0) {
            obj.child = temp;
          }
          result.push(obj);
        }
      }
      return result;
    },
    elTreeChange() {
      this.loreTureArrid = this.$refs.tree.getCheckedKeys();
    },
    searchLore() {
      let arr = [];
      this.TreeArray = [];
      this.defaultExpendedKeys = [];
      this.loreArr.forEach((i) => {
        if (i.name.indexOf(this.pointInput) >= 0) {
          arr.push(i.id);
          if (i.child.length == 0) {
            this.TreeArray.push(i);
          }
        }
      });
      if (arr.length == 0) {
        this.$message({
          message: "没有找到相似知识点",
          type: "warning",
        });
        return;
      }
      this.defaultExpendedKeys = arr;
    },
    changeUploadTeachingPlanFileType(type) {
      this.knowledgeContentType = type;
      this.uploadTeachingPlanFilePoints = type;
    },
    //章节
    getTeachingBooks() {
      getTeachingBooksList({
        levelId: this.getLevelId,
        subjectId: this.getSubjectId,
      }).then((res) => {
        this.textbooksArr = res.data.data;
      });
    },

    changeTab(type, items) {
      this.$parent.changeResourceType(type, items);
    },
    getBaseData() {
      let tab_data = getStore({ name: "currentItems" });
      if (tab_data) {
        this.currentTypeId = tab_data.tab_id;
        this.searchItems = tab_data.search_items;
      }
    },

    handleKeyDown(e) {
      let key = null;
      if (window.event === undefined) {
        key = e.keyCode;
      } else {
        key = window.event.keyCode;
      }
      if (key === 13) {
        //触发的事件
        this.searchList();
      }
    },

    downloadExamPaper(examPaper) {
      if (examPaper.filePath == null || examPaper.filePath.length === 0) {
        this.$message.error("文件不存在！");
        return;
      }
      // 下载试卷增加试卷的下载数
      updateDownloadCount(examPaper.id);
      if (
        examPaper.filePath.endsWith(".doc") ||
        examPaper.filePath.endsWith(".docx") ||
        examPaper.filePath.endsWith(".pdf") ||
        examPaper.filePath.endsWith(".ppt") ||
        examPaper.filePath.endsWith(".pptx")
      ) {
        this.downloadRequest(this.CDN + examPaper.filePath, examPaper.name);
      } else {
        this.fullscreenLoading = true;
        getExamPaperImgZip(examPaper.id).then((response) => {
          this.downloadRequest(response.data.data.url, response.data.data.name);
          this.fullscreenLoading = false;
          this.getList();
        });
      }
    },
    downloadRequest(url, name) {
      let request = new XMLHttpRequest();
      request.open("GET", url, true);
      request.responseType = "blob";
      request.onload = function () {
        let url = window.URL.createObjectURL(request.response);
        let a = document.createElement("a");
        a.href = url;
        a.download = name;
        a.click();
      };
      request.send();
      this.getList();
    },
    pageList(i) {
      this.searchItems.current = i;
      this.getList();
    },
    setItem(val, list) {
      list.map((index) => {
        index.isClick = false;
        if (index.value == val.value) {
          index.isClick = true;
        }
      });
      this.searchItems.current = 1;
      this.getList();
    },
    // 初始化一下需要使用的搜索数组
    onloadList() {
      let arr = JSON.parse(JSON.stringify(this.gradeOptions));
      // 搜索用的年级
      this.gradeOptionsCopy.push({ label: "全部", value: 0, isClick: true });
      arr.forEach((i) => {
        i.isClick = false;
        this.gradeOptionsCopy.push(i);
      });
      this.refreahInitData();
    },
    refreahInitData() {
      //年级
      this.gradeOptionsCopy.forEach((item) => {
        item.isClick = false;
        if (item.value == this.searchItems.resGradeId) {
          item.isClick = true;
        }
      });
      //类型
      this.teachingPlanType.forEach((item) => {
        item.isClick = false;
        if (item.value == this.searchItems.fileType) {
          item.isClick = true;
        }
      });
    },
    menuClick(menu) {
      if (menu.isClick) {
        // 重复点击已选择的菜单
        return;
      }
      this.menuList.map((menuItem) => {
        menuItem.isClick = false;
        if (menu.type === menuItem.type) {
          menuItem.isClick = true;
          this.searchItems.current = 1;
          this.searchItems.pageType = menu.type;
          this.getList();
        }
      });
    },
    searchList() {
      this.searchItems.current = 1;
      this.getList();
    },
    getList() {
      this.searchItems.pageType = this.menuList.filter(
        (item) => item.isClick
      )[0].type;
      this.searchItems.resGradeId = this.gradeOptionsCopy.filter(
        (item) => item.isClick
      )[0].value;
      this.searchItems.fileType = this.teachingPlanType.filter(
        (item) => item.isClick
      )[0].value;
      if (this.dateValue && this.dateValue.length > 0) {
        this.searchItems.startTime = this.dateValue[0];
        this.searchItems.endTime = this.dateValue[1];
      } else {
        this.searchItems.startTime = "";
        this.searchItems.endTime = "";
      }
      this.searchItems.type = this.currentTypeId;
      this.searchItems.level = this.getLevelId;
      this.searchItems.subjectId = this.getSubjectId;
      getMyteachingPlan(this.searchItems).then((res) => {
        this.pageData = res.data.data.records;
        this.total = res.data.data.total;
      });
    },
    uploadExamPaperDialog() {
      if (this.getLevelId) {
        this.ruleForm.level = this.getLevelId;
        this.changeLevel();
      }
      if (this.getSubjectId) {
        this.ruleForm.subjectId = this.getSubjectId;
      }
      let schoolId = getStore({ name: "school_id" });
      if (schoolId && schoolId > 0) {
        getObj(schoolId).then((response) => {
          let province = response.data.data.province;
          let city = response.data.data.city;
          if (province && province.length > 0) {
            this.ruleForm.province = province;
            if (city && city.length > 0) {
              this.changeProvince(null, city);
            } else {
              this.changeProvince();
            }
          }
        });
      }
      this.uploadDialogVisible = true;
    },
    // 修改学段后，修改年级
    changeLevel() {
      if (this.ruleForm.level === "2") {
        this.gradeOptions = gradeMiddleOptions;
      } else if (this.ruleForm.level === "3") {
        this.gradeOptions = gradeHighOptions;
      } else {
        this.gradeOptions = [];
      }
    },
    // 获取省列表
    getProvinceList() {
      getProvinceList().then((response) => {
        let provinces = response.data.data;
        if (provinces && provinces.length > 0) {
          this.provinceOptions = [];
          provinces.forEach((item) => {
            this.provinceOptions.push({ value: item.value, label: item.label });
          });
        }
      });
    },
    // 获取市列表
    getCityList() {
      getCityList({ province: this.ruleForm.province }).then((response) => {
        let cities = response.data.data;
        if (cities && cities.length > 0) {
          this.cityOptions = [];
          cities.forEach((item) => {
            this.cityOptions.push({ value: item.value, label: item.label });
          });
        }
      });
    },
    // 获取区列表
    getDistrictList() {
      getDistrictList({ city: this.ruleForm.city }).then((response) => {
        let districts = response.data.data;
        if (districts && districts.length > 0) {
          this.districtOptions = [];
          districts.forEach((item) => {
            this.districtOptions.push({ value: item.value, label: item.label });
          });
        }
      });
    },
    // 修改省事件
    changeProvince() {
      this.ruleForm.city = "";
      this.ruleForm.district = "";
      this.getCityList();
    },
    // 修改市事件
    changeCity() {
      this.ruleForm.district = "";
      this.getDistrictList();
    },
    gotoUploadStep2() {
      let isValid = true;
      this.$refs.ruleForm.validate((valid) => {
        if (!valid) {
          isValid = false;
        }
      });
      if (!isValid) {
        return;
      }
      this.uploadStep = 2;
      this.getKnowledgeData();
    },
    gotoUploadStep3() {
      if (this.loreTureArrid.length < 1) {
        this.$message({
          message: "请选择相关知识点/章节",
          type: "warning",
        });
        return;
      }
      this.uploadStep = 3;
    },
    closeUploadDialog() {
      this.initUploadExamPaperDialog();
    },
    initUploadExamPaperDialog() {
      this.ruleForm = this.ruleFormReset;
      this.uploadTeachingPlanFileType = 1;
      this.uploadExamPaperFileData = new FormData();
      this.uploadExamPaperFileList = [];
      this.uploadExamPaperAccept =
        this.currentTypeId == 1 ? ".docx" : ".ppt,.pptx";
      this.uploadExamPaperMultiple = false;
      this.uploadExamPaperLimit = 1;
      this.uploadExamPaperFileListEle = [];
      this.uploadStep = 1;
      //知识点
      this.knowledgeData = [];
      this.knowledgeContentType = 1;
      this.uploadTeachingPlanFilePoints = 1;
    },
    changeUploadExamPaperFileType(fileType) {
      this.uploadTeachingPlanFileType = fileType;
      this.uploadExamPaperFileData = new FormData();
      this.uploadExamPaperFileList = [];
      if (this.uploadTeachingPlanFileType === 1) {
        this.uploadExamPaperAccept = ".docx,.doc";
        this.uploadExamPaperMultiple = false;
        this.uploadExamPaperLimit = 1;
      } else if (this.uploadTeachingPlanFileType === 2) {
        this.uploadExamPaperAccept = ".pdf";
        this.uploadExamPaperMultiple = false;
        this.uploadExamPaperLimit = 1;
      } else if (this.uploadTeachingPlanFileType === 3) {
        this.uploadExamPaperAccept = ".jpg,.jpeg,.png,.bmp,.gif";
        this.uploadExamPaperMultiple = true;
        this.uploadExamPaperLimit = 100;
      } else if (this.uploadTeachingPlanFileType === 4) {
        this.uploadExamPaperAccept = ".ppt,.pptx";
        this.uploadExamPaperMultiple = false;
        this.uploadExamPaperLimit = 1;
      }
      this.uploadExamPaperFileListEle = [];
    },
    uploadExamPaperOnChange(file, fileList) {
      let existFile = fileList
        .slice(0, fileList.length - 1)
        .find((f) => f.name === file.name);
      if (existFile) {
        this.$message.error("当前文件已经存在！");
        fileList.pop();
      }
      let checkRes = this.checkFile(file);
      if (!checkRes) {
        fileList.pop();
      }
      this.uploadExamPaperFileList = fileList;
    },
    checkFile(file) {
      if (this.uploadTeachingPlanFileType === 1) {
        if (!file.name.endsWith(".docx") && !file.name.endsWith(".doc")) {
          this.$message.error("只能上传 word 文件！");
          return false;
        }
      } else if (this.uploadTeachingPlanFileType === 2) {
        if (!file.name.endsWith(".pdf")) {
          this.$message.error("只能上传 pdf 文件！");
          return false;
        }
      } else if (this.uploadTeachingPlanFileType === 3) {
        if (
          !file.name.endsWith(".jpg") &&
          !file.name.endsWith(".jpeg") &&
          !file.name.endsWith(".png") &&
          !file.name.endsWith(".bmp") &&
          !file.name.endsWith(".gif")
        ) {
          this.$message.error("只能上传图片文件！");
          return false;
        }
      } else if (this.uploadTeachingPlanFileType === 4) {
        if (!file.name.endsWith(".ppt") && !file.name.endsWith(".pptx")) {
          this.$message.error("只能上传 ppt/pptx 文件！");
          return false;
        }
      }

      let is30M = file.size / 1024 / 1024 <= 30;
      if (!is30M) {
        this.$message.error("文件大小不能超过 30 MB！");
        return false;
      }
      return true;
    },
    uploadExamPaperBeforeRemove(file, fileList) {
      this.uploadExamPaperFileList = fileList;
    },
    uploadExamPaperOnExceed() {
      this.$message.warning(
        "最多只能上传 " + this.uploadExamPaperLimit + " 个文件！"
      );
    },
    uploadExamPaperHttpRequest(file) {
      this.uploadExamPaperFileData.append("file", file.file);
    },
    uploadExamPaper() {
      if (this.uploadExamPaperFileList.length === 0) {
        return;
      }
      this.loading = true;
      this.uploadExamPaperFileData = new FormData();
      this.uploadExamPaperFileData.append(
        "name",
        encodeURIComponent(this.ruleForm.name)
      );
      this.uploadExamPaperFileData.append("level", this.ruleForm.level);
      this.uploadExamPaperFileData.append(
        "resGradeId",
        this.ruleForm.resGradeId
      );
      this.uploadExamPaperFileData.append("subjectId", this.ruleForm.subjectId);
      this.uploadExamPaperFileData.append("type", this.currentTypeId); //currentTypeId:1-教案 2-课件
      this.uploadExamPaperFileData.append("province", this.ruleForm.province);
      this.uploadExamPaperFileData.append("city", this.ruleForm.city);
      this.uploadExamPaperFileData.append("district", this.ruleForm.district);
      this.uploadExamPaperFileData.append("year", this.ruleForm.year);
      //知识点
      this.uploadExamPaperFileData.append(
        "knowledgeIds",
        this.ajaxLoreIds.join("#")
      );
      this.uploadExamPaperFileData.append(
        "knowledgeNames",
        encodeURIComponent(this.loreTureArrName.join("#"))
      );
      //章节(暂时不做)
      this.uploadExamPaperFileData.append("chapterIds", "");
      this.uploadExamPaperFileData.append("chapterNames", "");

      this.uploadExamPaperFileData.append("share", this.ruleForm.share);
      this.uploadExamPaperFileData.append(
        "fileType",
        this.uploadTeachingPlanFileType
      );
      this.$refs.uploadExamPaper.submit(); // 触发 uploadExamPaperHttpRequest 方法，依次在 uploadExamPaperFileData 里增加文件
      // eslint-disable-next-line no-unused-vars
      uploadTeachingPlan(this.uploadExamPaperFileData).then((response) => {
        if (this.currentTypeId == 1) {
          this.$message.success("上传教案成功！");
        } else {
          this.$message.success("上传课件成功！");
        }

        this.uploadDialogVisible = false;
        this.loading = false;
        this.initUploadExamPaperDialog();
        this.getList();
      });
    },
    toDetail(id) {
      setStore({
        name: "myResourceSearchItems",
        content: { tab_id: this.currentTypeId, search_items: this.searchItems },
      });
      updateViewCount(id);
      if (this.currentTypeId == 1) {
        this.$router.push({
          name: "TeachingPlanDetails",
          query: {
            id: id,
            backurl: "/res/myresource",
          },
        });
      } else if (this.currentTypeId == 2) {
        this.$router.push({
          name: "CoursewareDetail",
          query: {
            id: id,
            backurl: "/res/myresource",
          },
        });
      }
    },
    addFavoriteList(item) {
      favTeachingPlan(item.id).then(() => {
        this.setList(item.id, true);
        this.$message.success("收藏成功");
      });
    },
    cancelFavorite(item) {
      unfavTeachingPlan(item.id).then(() => {
        this.setList(item.id, false);
        this.$message.success("取消收藏成功");
      });
    },
    setList(id, type) {
      //type:0-未收藏 1-收藏
      this.pageData.map((index) => {
        if (id == index.id) {
          if (type) {
            index.isStar = 1;
          } else {
            index.isStar = 0;
          }
        }
      });
    },
    downloadTeachingPlan(plan) {
      this.downloadExamPaper(plan);
    },
    deleteTeachingPlan(plan) {
      //resource/teachassist/delete/{plan.id}  get
      delTeachingPlan(plan.id).then(() => {
        this.$message.success("删除教案成功");
        this.getList();
      });
    },
  },
};
</script>
<style lang="scss">
.my-teaching-plan {
  .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    color: $primary-color;
  }
  .el-tabs--border-card > .el-tabs__header .el-tabs__item:hover {
    color: $primary-color;
  }
  .el-dialog {
    max-width: 816px;
    .el-dialog__header {
      padding: 24px 32px 0;
      .el-dialog__title {
        font-size: 20px;
        font-weight: 600;
      }
      .el-dialog__headerbtn {
        right: 29px;
        top: 29px;
      }
    }
    .el-dialog__body {
      padding: 24px 32px;
      .el-form {
        border-bottom: 1px solid #e0e0e0;
        margin-bottom: 20px;
        // &:last-child {
        //   border-bottom: 1px solid #e0e0e0;
        // }
      }

      .form-item-box {
        border-top: 1px solid #e0e0e0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .checkbox-item {
          width: 100%;
          // padding-left: 100px;
          margin-bottom: 8px;
        }
        .el-date-editor.el-input {
          width: 100%;
        }
        .item-box-btn {
          display: flex;
          justify-content: space-between;
          p {
            color: #2196f3;
            font-size: 14px;
            margin-right: 8px;
            margin-left: 8px;
            cursor: pointer;
            &:last-child {
              margin-right: 0;
            }
          }
        }
        .el-select {
          width: 100%;
        }
        .el-form-item {
          width: 100%;
        }
      }
      .two-box {
        .el-form-item {
          width: 49%;
        }
      }
      .el-form-item {
        padding: 8px 0;
        margin-bottom: 0;
      }
    }
    .el-form-item__label {
      text-align: left;
      margin-bottom: 0;
    }
    .el-input {
      input {
        background-color: #f5f5f5;
        border-color: #f5f5f5;
      }
    }

    .el-form-item.is-required:not(.is-no-asterisk)
      .el-form-item__label-wrap
      > .el-form-item__label:before,
    .el-form-item.is-required:not(.is-no-asterisk)
      > .el-form-item__label:before {
      content: "";
    }
  }
  .el-select .el-input.is-focus .el-input__inner {
    border-color: #f5f5f5;
  }
  .el-select .el-input__inner:focus {
    border-color: #f5f5f5;
  }
  .el-select .el-input__inner:hover {
    border-color: #f5f5f5;
  }
  .el-icon-arrow-up:before {
    content: "\e78f";
  }
  .el-select .el-input .el-select__caret {
    color: rgba(0, 0, 0, 0.5);
  }
  .secrch-item {
    width: 176px;
    margin-bottom: 16px;
    input {
      // background-color: #f5f5f5;
      border-color: #f5f5f5;
    }
  }
  .upload-button .el-upload .el-upload-dragger {
    width: 752px;
  }
  .upload-teaching-plan-dialog-body {
    .by-points {
      .el-input {
        width: 180px;
      }
      .el-input__inner {
        border: dashed 2px #f5f5f5 !important;
        background-color: #ffffff !important;
        border-radius: 4px;
        height: 30px;
        width: 180px;
      }
    }
    .by-chapters {
      .el-select {
        margin-left: 68px;
      }
      .el-input__inner {
        width: 272px;
        height: 40px;
        color: #000000;
      }
      .el-select__caret {
        color: rgba(0, 0, 0, 1);
      }
    }
  }
}
</style>

<style scoped lang="scss">
.my-teaching-plan {
  width: 1220px;
  margin: 10px auto 50px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .left-box,
  .right-box {
    padding: 20px;
    border: 1px solid #dddddd;
  }
  .left-box {
    .menu {
      margin-top: 12px;
      background: #fff;
      border: 1px solid #dcdfe6;
      padding: 0 15px;
      box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%), 0 0 6px 0 rgb(0 0 0 / 4%);
    }
    .menu-list {
      display: flex;
      align-items: center;
      padding: 18px 0;
      border-bottom: 1px solid #e9f1f3;
      cursor: pointer;
      &:last-child {
        border-bottom: none;
      }
      img {
        width: 24px;
        margin-right: 4px;
      }
      .on {
        color: $primary-color;
      }
    }
  }
  .right-box {
    width: 900px;
    .list-box {
      margin-top: 20px;
      .item-list {
        .title {
          cursor: pointer;
        }
      }
      .none-list {
        img {
          width: 400px;
          display: block;
          margin: 20px auto;
        }
        p {
          text-align: center;
          color: #999999;
        }
      }
    }
    .resource-tab {
      display: flex;
      padding: 0 15px;
      border-bottom: 2px solid rgba(16, 198, 148, 0.2);
      margin-top: 32px;
      .tab-item {
        position: relative;
        padding-bottom: 5px;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 22px;
        }
        .type-name {
          height: 20px;
        }
        .underline {
          visibility: inherit;
          height: 3px;
          background-color: #2474ed;
          border-radius: 2px;
          position: absolute;
          top: 24px;
          width: 28px;
        }
      }
      .active {
        color: #2474ed;
      }
    }
    .list-box-bottom {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
    .search-item {
      display: flex;
      flex-flow: wrap;
      margin-bottom: 8px;
      .input-with-select {
        width: 300px;
      }
      .title {
        margin: 0 5px 3px 5px;
        padding: 2px 10px;
        color: #333;
      }
      p {
        margin: 0 5px 3px 5px;
        padding: 2px 10px;
        color: #333;
        cursor: pointer;
        &:hover {
          background: $primary-color;
          color: #fff;
          border-radius: 3px;
        }
      }
      .on {
        background: $primary-color;
        color: #fff;
        border-radius: 3px;
      }
    }
    .search-item-old {
      justify-content: space-between;
      padding: 0 14px;
    }
    .item-list {
      width: 860px;
      padding: 12px;
      border: 1px solid #e0e0e0;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      .el-icon-star-off,
      .el-icon-star-on {
        color: $primary-color;
        font-size: 18px;
        vertical-align: bottom;
        cursor: pointer;
      }
      .thumbnail {
        width: 100px;
        height: 120px;
        background-color: aliceblue;
        margin-right: 20px;
        position: relative;
        display: none;
        .count {
          position: absolute;
          right: 2px;
          bottom: 2px;
          color: #2474ed;
        }
      }
      .info {
        width: 100%;
        .base-info {
          display: flex;
          justify-content: space-between;
          .info {
            display: flex;
            // justify-content: space-between;
            margin-bottom: 20px;
            align-items: center;
            .icon {
              width: 128px;
            }
            .name {
              cursor: pointer;
              font-size: 16px;
              font-weight: 500;
              margin-right: 12px;
            }
          }
          .btn {
            cursor: pointer;
          }
        }
        .more-info {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .plan-data {
            .data-item {
              color: rgba(0, 0, 0, 0.5);
              &:not(:last-child) {
                margin-right: 60px;
              }
            }
          }
          .btn-all {
            border-radius: 4px;
            background: #2474ed;
            border-color: #2474ed;
            color: #ffffff;
            font-size: 16px;
            padding: 7px 12px;
            cursor: pointer;
            img {
              width: 18px;
              margin-left: 20px;
            }
          }
        }
      }

      .my-group-box {
        margin-top: 27px;
        display: flex;
        justify-content: space-between;
        .group-box-left {
          span {
            margin-right: 8px;
            color: rgba(0, 0, 0, 0.5);
          }
        }
        .group-box-right {
          span {
            color: rgba(0, 0, 0, 0.5);
            margin-right: 8px;
          }
          .title {
            cursor: pointer;
            color: $primary-color;
          }
        }
      }
    }
  }
  .left {
    float: left;
  }
  .right {
    float: right;
  }
  .clear-both {
    clear: both;
  }

  .upload-exam-paper-dialog {
    .upload-teaching-plan-dialog-header {
      height: 30px;
      .filetype {
        display: inline-block;
        margin-right: 20px;
        cursor: pointer;
      }
      .selected-filetype {
        color: #2474ed;
      }
    }
    .upload-teaching-plan-dialog-body {
      .by-points {
        display: flex;
        justify-content: space-between;
        .search-item {
          display: flex;
          align-items: center;
          .btn {
            background-color: #2474ed;
            color: #ffffff;
            border-radius: 4px;
            margin-left: 12px;
            font-size: 14px;
            align-items: center;
            display: flex;
            height: 25px;
            width: 76px;
            justify-content: center;
            cursor: pointer;
          }
        }
      }
      .by-chapters {
        .books {
          padding: 8px 0;
          border-top: 1px solid #e0e0e0;
          border-bottom: 1px solid #e0e0e0;
          .el-input__inner {
            width: 272px;
            height: 40px;
          }
        }
      }
    }
    .upload-button {
      width: 752px;
      .upload-text {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
</style>
