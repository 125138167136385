<!-- 我的资源-微课 -->
<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" class="my-teaching-plan">
    <div class="left-box">
      <el-button
        type="primary"
        class="btn-all"
        style="width: 246px"
        @click="createMicroLessonDialog"
      >
        创建微课<i class="el-icon-upload el-icon--right"></i>
      </el-button>
      <div class="menu">
        <div v-for="(menu, index) in menuList" :key="index" class="menu-list">
          <div @click="menuClick(menu)">
            <img :src="menu.img" alt="" />
            <span :class="{ on: menu.isClick }">{{ menu.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="right-box">
      <div class="search-box">
        <div class="search-item">
          <div class="title">年级：</div>
          <template v-for="(v, i) in gradeOptionsCopy">
            <p
              :key="i"
              :class="{ on: v.isClick }"
              @click="setItem(v, gradeOptionsCopy)"
            >
              {{ v.label }}
            </p>
          </template>
        </div>
        <div class="search-item">
          <div class="title">年份：</div>
          <template v-for="(v, i) in microLessonsYears">
            <p
              :key="i"
              :class="{ on: v.isClick }"
              @click="setItem(v, microLessonsYears)"
            >
              {{ v.label }}
            </p>
          </template>
        </div>

        <div class="search-item">
          <div class="title">类型：</div>
          <template v-for="(v, i) in microLessonsType">
            <p
              :key="i"
              :class="{ on: v.isClick }"
              @click="setItem(v, microLessonsType)"
            >
              {{ v.label }}
            </p>
          </template>
        </div>
        <div class="search-item search-item-old">
          <div>
            收藏日期:
            <el-date-picker
              v-model="dateValue"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="searchList"
            >
            </el-date-picker>
          </div>
          <el-input
            v-model="searchItems.keyword"
            placeholder="请输入内容"
            class="input-with-select"
            @keyup.enter="searchList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="searchList"
            ></el-button>
          </el-input>
        </div>
      </div>
      <!-- 类别tab -->
      <div class="resource-tab">
        <div
          v-for="(item, key) in resourceTypeList"
          :key="key"
          class="tab-item"
          :class="{ active: currentTypeId == key }"
          @click="changeTab(key, searchItems)"
        >
          <div class="type-name">{{ item }}</div>
          <div v-if="currentTypeId == key" class="underline"></div>
        </div>
      </div>
      <div class="list-box">
        <div class="list-title"></div>
        <div
          v-for="(item, i) in pageData"
          :key="i"
          class="item-list"
          :class="{ mr12: (i + 1) % 3 != 0 }"
          @click="showDetail(item)"
        >
          <!-- 缩略图 -->
          <div class="thumbnail">
            <img :src="item.imgCover" alt="" />
          </div>
          <div class="info">
            <div class="base-info">
              <div class="name" @click="toDetail(item.id)">{{ item.name }}</div>
              <div class="chapters">
                共{{ item.countLesson }}节 <span style="margin: 0 8px">|</span>
                {{ item.countLearn }}人已学
              </div>
            </div>
            <div class="more-info">
              <div class="owner-ship">
                {{ getLabelByValue(item.status % 3, microLessonsOwnership) }}
              </div>
              <div class="fav">
                <span>收藏</span>
                <i
                  v-if="item.isStar == 1"
                  class="el-icon-star-on"
                  @click.stop="cancelFavorite(item)"
                ></i>
                <i
                  v-else
                  class="el-icon-star-off"
                  @click.stop="addFavoriteList(item)"
                ></i>
              </div>
            </div>
          </div>
        </div>
        <div v-if="pageData.length == 0" class="none-list">
          <img src="@/static/resources/none.svg" alt="" />
          <p>暂未找到数据</p>
        </div>
        <div v-if="total > 0" class="list-box-bottom">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            @current-change="pageList"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  gradeHighOptions,
  gradeMiddleOptions,
  subjectOptions,
  gradeOptions,
} from "@/core/util/constdata";
import { setStore, getStore } from "@/core/util/store";
import {
  getMyMicroLessons,
  unfavMicroLesson,
  favMicroLesson,
  updateViewCount,
} from "@/core/api/resource/myResource";
const menuList = [
  {
    name: "我的全部",
    type: 1,
    isClick: true,
    img: require("@/static/resources/group.png"),
  },
  // {
  //   name: "我的收藏",
  //   type: 2,
  //   isClick: false,
  //   img: require("@/static/resources/collect.png")
  // },
  {
    name: "我的上传",
    type: 3,
    isClick: false,
    img: require("@/static/resources/up.png"),
  },
];
const microLessonsType = [
  {
    isClick: true,
    label: "全部",
    value: 0,
  },
  {
    isClick: true,
    label: "知识点/章节微课",
    value: 1,
  },
  {
    isClick: true,
    label: "专题微课",
    value: 2,
  },
  {
    isClick: true,
    label: "试题微课",
    value: 3,
  },
];
const resourceTypeList = ["试卷", "教案", "教材", "微课"];
const microLessonsOwnership = [
  {
    label: "系统",
    value: 0,
  },
  {
    label: "校本",
    value: 1,
  },
  {
    label: "个人",
    value: 2,
  },
];
export default {
  name: "Myresource",
  components: {},
  props: {
    resourseTypeData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      //知识点
      defaultProps: {
        children: "child",
        label: "name",
      },
      newKeys: new Date().getTime(),

      fullscreenLoading: false,
      modelKey: 1,
      listId: 0,
      activeName: "first",
      loading: false, // 载入
      menuList: menuList,
      //tab
      currentTypeId: 1, //0-试卷 1-教案 2-教材 3-微课
      resourceTypeList: resourceTypeList,
      interactiveData: this.resourseTypeData, //tab切换时传输的数据
      // option数据
      microLessonsType: microLessonsType,
      microLessonsOwnership: microLessonsOwnership,
      gradeHighOptions: gradeHighOptions,
      gradeMiddleOptions: gradeMiddleOptions,
      gradeOptions: gradeOptions,
      subjectOptions: subjectOptions,

      // 搜索
      searchItems: {
        pageType: 1, //1-我的全部 2-我的收藏 3-我的上传
        type: 0, //1-教案 2-课件
        level: "",
        subjectId: "",
        resGradeId: 0,
        fileType: 0, //教辅文件类型 1-word 2-pdf 3-图片 4-ppt 5-excel
        startTime: "",
        endTime: "",
        keyword: "",
        size: 12,
        current: 1,
        rType: 1,
        year: 0, //年份
        beforeYear: 0, //更早: 全部-year=0,beforeYear=0 具体某年份-year=年份,beforeYear=0 更早-year=0,beforeYear=列举出来的最小年份
      },
      pageIndex: 1,
      pageData: [],
      gradeOptionsCopy: [],
      dateValue: [],
      total: 0,
      currentExamPaper: {},
      CDN: "https://static.wtjy.com/",
      microLessonsYears: [],
    };
  },
  computed: {
    ...mapGetters({ getLevelId: "getLevelId", getSubjectId: "getSubjectId" }),
  },
  watch: {
    dateValue() {
      if (this.dateValue && this.dateValue.length > 0) {
        this.searchItems.startTime = this.dateValue[0];
        this.searchItems.endTime = this.dateValue[1];
      }
    },
  },
  created() {
    this.resetBasePageData();
    this.onloadList();
    this.getList();
    window.addEventListener("keydown", this.handleKeyDown, true);
  },
  destroyed() {
    window.removeEventListener("keydown", this.handleKeyDown, true);
  },
  methods: {
    showDetail(v) {
      this.$router.push({
        name: "ShowMicroLesson",
        query: { id: v.id },
      });
    },
    resetBasePageData() {
      if (this.interactiveData) {
        this.currentTypeId = this.interactiveData.type;
        this.searchItems = Object.assign(
          this.searchItems,
          this.interactiveData.searchItems
        );
      }
      this.dateValue = [this.searchItems.startTime, this.searchItems.endTime];
    },
    getLabelByValue(value, data) {
      let label = "";
      data.forEach((item) => {
        if (item.value === value) {
          label = item.label;
        }
      });
      return label;
    },

    changeTab(type, items) {
      this.$parent.changeResourceType(type, items);
    },
    getBaseData() {
      let tab_data = getStore({ name: "currentItems" });
      if (tab_data) {
        this.currentTypeId = tab_data.tab_id;
        this.searchItems = tab_data.search_items;
      }
    },

    handleKeyDown(e) {
      let key = null;
      if (window.event === undefined) {
        key = e.keyCode;
      } else {
        key = window.event.keyCode;
      }
      if (key === 13) {
        //触发的事件
        this.searchList();
      }
    },

    pageList(i) {
      this.searchItems.current = i;
      this.getList();
    },
    setItem(val, list) {
      list.map((index) => {
        index.isClick = false;
        if (index.value == val.value) {
          index.isClick = true;
        }
      });
      this.searchItems.current = 1;
      this.getList();
    },
    // 初始化一下需要使用的搜索数组
    onloadList() {
      let arr = JSON.parse(JSON.stringify(this.gradeOptions));
      // 搜索用的年级
      this.gradeOptionsCopy.push({ label: "全部", value: 0, isClick: true });
      arr.forEach((i) => {
        i.isClick = false;
        this.gradeOptionsCopy.push(i);
      });
      //年份
      let _year = new Date().getFullYear();
      this.microLessonsYears = [{ label: "全部", value: 0, isClick: true }];
      for (let i = _year; i > _year - 4; i--) {
        let _item = { label: i, value: i, isClick: false };
        this.microLessonsYears.push(_item);
      }
      this.microLessonsYears.push({
        label: "更早",
        value: "001",
        isClick: false,
      });
      this.refreahInitData();
    },
    refreahInitData() {
      //年级
      this.gradeOptionsCopy.forEach((item) => {
        item.isClick = false;
        if (item.value == this.searchItems.resGradeId) {
          item.isClick = true;
        }
      });
      //类型
      this.microLessonsType.forEach((item) => {
        item.isClick = false;
        if (item.value == this.searchItems.fileType) {
          item.isClick = true;
        }
      });
      //年份
      this.microLessonsYears.forEach((item) => {
        item.isClick = false;
        if (this.searchItems.beforeYear == 0) {
          if (item.value == this.searchItems.year) {
            item.isClick = true;
          }
        } else {
          if (item.value == 1) {
            item.isClick = true;
          }
        }
      });
    },
    menuClick(menu) {
      if (menu.isClick) {
        // 重复点击已选择的菜单
        return;
      }
      this.menuList.map((menuItem) => {
        menuItem.isClick = false;
        if (menu.type === menuItem.type) {
          menuItem.isClick = true;
          this.searchItems.current = 1;
          this.searchItems.pageType = menu.type;
          this.getList();
        }
      });
    },
    searchList() {
      this.searchItems.current = 1;
      this.getList();
    },
    getList() {
      this.searchItems.pageType = this.menuList.filter(
        (item) => item.isClick
      )[0].type;
      this.searchItems.resGradeId = this.gradeOptionsCopy.filter(
        (item) => item.isClick
      )[0].value;

      this.searchItems.year = this.microLessonsYears.filter(
        (item) => item.isClick
      )[0].value;
      //year: 0-全部(beforeYear=0) 1-更早(year=0,beforeYear=year)
      if (this.searchItems.year == "001") {
        this.searchItems.year = 0;
        this.searchItems.beforeYear = new Date().getFullYear() - 4 + 1;
      } else {
        this.searchItems.beforeYear = 0;
      }

      if (this.dateValue && this.dateValue.length > 0) {
        this.searchItems.startTime = this.dateValue[0];
        this.searchItems.endTime = this.dateValue[1];
      } else {
        this.searchItems.startTime = "";
        this.searchItems.endTime = "";
      }

      this.searchItems.type = this.microLessonsType.filter(
        (item) => item.isClick
      )[0].value;

      // this.searchItems.type = this.currentTypeId;
      this.searchItems.level = this.getLevelId;
      this.searchItems.subjectId = this.getSubjectId;
      getMyMicroLessons(this.searchItems).then((res) => {
        this.pageData = res.data.data.records;
        this.total = res.data.data.total;
      });
    },
    createMicroLessonDialog() {
      setStore({
        name: "myResourceSearchItems",
        content: { tab_id: this.currentTypeId, search_items: this.searchItems },
      });
      this.$router.push({
        name: "CreateMicroLesson",
        query: {
          level: this.getLevelId,
          subject: this.getSubjectId,
          grade: this.searchItems.resGradeId,
        },
      });
    },

    toDetail(id) {
      setStore({
        name: "myResourceSearchItems",
        content: { tab_id: this.currentTypeId, search_items: this.searchItems },
      });
      updateViewCount(id);
      // this.$router.push({
      //   name: "CoursewareDetail",
      //   query: {
      //     id: id,
      //     backurl: "/res/myresource"
      //   }
      // });
    },
    addFavoriteList(item) {
      favMicroLesson(item.id).then(() => {
        this.setList(item.id, true);
        this.$message.success("收藏成功");
      });
    },
    cancelFavorite(item) {
      unfavMicroLesson(item.id).then(() => {
        this.setList(item.id, false);
        this.$message.success("取消收藏成功");
      });
    },
    setList(id, type) {
      //type:0-未收藏 1-收藏
      this.pageData.map((index) => {
        if (id == index.id) {
          if (type) {
            index.isStar = 1;
          } else {
            index.isStar = 0;
          }
        }
      });
    },
  },
};
</script>
<style lang="scss">
.my-teaching-plan {
  .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    color: $primary-color;
  }
  .el-tabs--border-card > .el-tabs__header .el-tabs__item:hover {
    color: $primary-color;
  }
}
</style>

<style scoped lang="scss">
.my-teaching-plan {
  width: 1220px;
  margin: 10px auto 50px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .left-box,
  .right-box {
    padding: 20px;
    border: 1px solid #dddddd;
  }
  .left-box {
    .menu {
      margin-top: 12px;
      background: #fff;
      border: 1px solid #dcdfe6;
      padding: 0 15px;
      box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%), 0 0 6px 0 rgb(0 0 0 / 4%);
    }
    .menu-list {
      display: flex;
      align-items: center;
      padding: 18px 0;
      border-bottom: 1px solid #e9f1f3;
      cursor: pointer;
      &:last-child {
        border-bottom: none;
      }
      img {
        width: 24px;
        margin-right: 4px;
      }
      .on {
        color: $primary-color;
      }
    }
  }
  .right-box {
    width: 900px;
    .list-box {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      // justify-content: center;
      .mr12 {
        margin-right: 12px;
      }
      .item-list {
        width: 278px;
        // border: 1px solid #e0e0e0;
        margin-bottom: 32px;
        .el-icon-star-off,
        .el-icon-star-on {
          color: $primary-color;
          font-size: 18px;
          vertical-align: bottom;
          cursor: pointer;
        }
        .thumbnail {
          width: 280px;
          height: 150px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .info {
          width: 100%;
          padding: 0 12px;
          .base-info {
            margin-bottom: 20px;
            align-items: center;
            .icon {
              width: 128px;
            }
            .name {
              cursor: pointer;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.85);
              margin: 12px 0 8px;
            }
            .chapters {
              color: rgba(0, 0, 0, 0.5);
            }
          }
          .more-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .owner-ship {
              color: rgba(255, 171, 0, 1);
            }
            .fav {
              color: rgba(0, 0, 0, 0.5);
              span {
                margin-right: 8px;
              }
            }
          }
        }
      }
      .none-list {
        margin: 0 auto;
        img {
          width: 400px;
          display: block;
          margin: 20px auto;
        }
        p {
          text-align: center;
          color: #999999;
        }
      }
    }
    .resource-tab {
      display: flex;
      padding: 0 15px;
      border-bottom: 2px solid rgba(16, 198, 148, 0.2);
      margin-top: 32px;
      .tab-item {
        position: relative;
        padding-bottom: 5px;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 22px;
        }
        .type-name {
          height: 20px;
        }
        .underline {
          visibility: inherit;
          height: 3px;
          background-color: #2474ed;
          border-radius: 2px;
          position: absolute;
          top: 24px;
          width: 28px;
        }
      }
      .active {
        color: #2474ed;
      }
    }
    .list-box-bottom {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
    .search-item {
      display: flex;
      flex-flow: wrap;
      margin-bottom: 8px;
      .input-with-select {
        width: 300px;
      }
      .title {
        margin: 0 5px 3px 5px;
        padding: 2px 10px;
        color: #333;
      }
      p {
        margin: 0 5px 3px 5px;
        padding: 2px 10px;
        color: #333;
        cursor: pointer;
        &:hover {
          background: $primary-color;
          color: #fff;
          border-radius: 3px;
        }
      }
      .on {
        background: $primary-color;
        color: #fff;
        border-radius: 3px;
      }
    }
    .search-item-old {
      justify-content: space-between;
      padding: 0 14px;
    }
  }
  .left {
    float: left;
  }
  .right {
    float: right;
  }
  .clear-both {
    clear: both;
  }
}
</style>
