<template>
  <div class="similarQuestion">
    <el-dialog title="同类题" :visible.sync="dialogVisible" width="800px">
      <div class="refresh-btn">
        <el-button type="text" icon="el-icon-refresh" @click="getNew()"
          >换一批</el-button
        >
      </div>

      <div v-loading="loading" class="question-box edit-scroll-style">
        <div v-for="(v, i) in examquestionData" :key="i" class="question-list">
          <div class="question-id">试题ID：{{ v.id }}</div>
          <div style="padding: 0 12px 12px" v-html="v.question"></div>
          <div class="btm-box">
            <div class="tips">
              <span> 更新{{ v.createTime | setTimeStyle }}</span>
              <!-- <span>{{ v.typeName }}</span> -->
              <span> {{ v.difficulty }} </span>
            </div>

            <div class="btn-box">
              <span :class="{ succ: v.isRelation }" @click="relation(v)">
                <template v-if="v.isRelation">已</template>收藏</span
              >
              <span @click="show(v)">解析</span>

              <el-button
                v-if="$parent.planQuestionList.indexOf(v.id) == -1"
                type="primary"
                class="btn-all"
                @click="$parent.exampaperplanquestion(v)"
              >
                加入试题篮
                <i class="el-icon-plus el-icon--right"></i>
              </el-button>
              <el-button
                v-else
                type="info"
                @click="$parent.exampaperplanquestionDel(v)"
              >
                移除试题篮
                <i class="el-icon-minus el-icon--right"></i>
              </el-button>
            </div>
          </div>
          <!-- 考情 -->
          <div v-if="situationArr.indexOf(v.id) != -1" class="situation-box">
            <template
              v-if="v.situation && v.situation.anyExamUseQuestion.length == 0"
            >
              数据尚未同步
            </template>
            <template v-else>
              <p>
                <span class="situation-box-name">考情：</span>本题共
                <span>{{ v.situation.countReply }}</span> 人使用,共{{
                  v.situation.examNum
                }}场考试
              </p>
              <div
                v-for="(m, n) in v.situation.anyExamUseQuestion"
                :key="n"
                class="situation-list"
              >
                <div class="situation-list-left">
                  <p>{{ m.examName }}</p>
                  <div>
                    <span class="situation-list-name">{{ m.examDate }}</span>
                    <span> | </span>
                    <span class="situation-list-name"
                      >共{{ m.countReply }}人参考</span
                    >
                    <span> | </span>
                    <span class="situation-list-name">{{ m.schoolLevel }}</span>
                  </div>
                </div>
                <div class="situation-list-right">
                  {{ m.scoreRate | setNumber }}
                </div>
              </div>
            </template>
          </div>
          <div v-if="showListArr.indexOf(v.id) != -1" class="analysis-box">
            <div v-if="v.newQuestion" class="new-question-box">
              <p v-if="v.newQuestion" class="question-tips">
                当前试题没有找到答案解析，已推荐同类题
              </p>
              <div class="analysis-item">
                【同类题】
                <div v-html="v.newQuestion"></div>
              </div>
              <el-button
                v-if="$parent.planQuestionList.indexOf(v.newId) == -1"
                type="primary"
                class="btn-all"
                @click="$parent.exampaperplanquestion(v, 1)"
              >
                加入试题篮
                <i class="el-icon-plus el-icon--right"></i>
              </el-button>
              <el-button
                v-else
                type="info"
                @click="$parent.exampaperplanquestionDel(v, 1)"
              >
                移除试题篮
                <i class="el-icon-minus el-icon--right"></i>
              </el-button>
            </div>

            <div
              v-if="v.knowledgeNames && v.knowledgeNames.split('#').length > 0"
              class="know-box analysis-item"
            >
              【知识点】
              <div v-for="(m, n) in v.knowledgeNames.split('#')" :key="n">
                {{ m }}
              </div>
            </div>
            <div v-if="v.answer && v.answer.length > 0" class="analysis-item">
              【答案】
              <div v-html="v.answer"></div>
            </div>
            <div
              v-if="v.analysis && v.analysis.length > 0"
              class="analysis-item"
            >
              【解答】
              <div v-html="v.analysis"></div>
            </div>
            <div v-if="v.analyse && v.analyse.length > 0" class="analysis-item">
              【分析】
              <div v-html="v.analyse"></div>
            </div>
            <div v-if="v.ability && v.ability.length > 0" class="analysis-item">
              【点评】
              <div v-html="v.ability"></div>
            </div>
            <!-- <div class="id-box">学科id：{{ v.xuekeId }}</div> -->
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  examquestionrelationMy,
  examquestionrelationDel,
  answer,
} from "@/core/api/resource/knowledgePointsPaper";

import { searchTraining } from "@/core/api/resource/breakdownPaper";
export default {
  name: "SimilarQuestion",
  data() {
    return {
      dialogVisible: false,
      loading: false,
      situationArr: [],
      showListArr: [],
      examquestionData: [],
      search: {
        pageIndex: 1,
        pageSize: 5,
      },
    };
  },
  created() {},
  methods: {
    // 切换是否显示页面列表
    show(v) {
      if (this.showListArr.indexOf(v.id) != -1) {
        this.showListArr.splice(this.showListArr.indexOf(v.id), 1);
        return;
      }
      answer(v.id).then((res) => {
        // console.log(res);
        if (res.data.data.id) {
          v.answer = res.data.data.answer;
          v.analyse = res.data.data.analyse;
          v.analysis = res.data.data.analysis;
          v.ability = res.data.data.ability;
          v.knowledgeNames = res.data.data.knowledgeNames;
          if (res.data.data.id != v.id) {
            v.newQuestion = res.data.data.question;
            // v.knowledgeNames = res.data.data.knowledgeNames;
            v.newId = res.data.data.id;
            v.newXuekeId = res.data.data.xuekeId;
          }
          if (this.showListArr.indexOf(v.id) == -1) {
            this.showListArr.push(v.id);
          } else {
            this.showListArr.splice(this.showListArr.indexOf(v.id), 1);
          }
        } else {
          this.$message({
            message: "未找到同类题和解析",
            type: "warning",
          });
        }
      });
    },
    getNew() {
      this.search.pageIndex++;
      this.getList();
    },
    // 收藏
    relation(v) {
      let data = {
        examQuestionId: v.id,
        type: 1,
      };
      if (v.isRelation == 1) {
        this.$confirm("确认取消收藏当前试题吗", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            examquestionrelationDel(data).then(() => {
              v.isRelation = 0;
              this.$message({
                message: "已取消收藏",
                type: "success",
              });
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消“取消收藏”操作",
            });
          });
      } else {
        examquestionrelationMy(data).then(() => {
          // console.log();
          this.$message({
            message: "已收藏",
            type: "success",
          });
          v.isRelation = 1;
        });
      }
    },
    getList() {
      let data = { ...this.search };
      this.loading = true;
      searchTraining(this.indexQuestion.id, data)
        .then((res) => {
          this.examquestionData = res.data.data.map((item) => {
            if (item.source) {
              item.question = item.question.replace(
                ">",
                `><span class='jyw-question-tips'>${item.source}</span>`
              );
            }
            return item;
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    init(val) {
      this.indexQuestion = val;
      this.dialogVisible = true;
      this.pageIndex = 1;
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/sass/qusetion.scss";
.similarQuestion {
  .refresh-btn {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 8px;
    .el-button {
      cursor: pointer;
    }
  }
  .question-box {
    max-height: calc(100vh - 340px);
    min-height: 50px;
  }
}
</style>
