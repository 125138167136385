<!-- 校本资源-教材 -->
<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" class="teaching-material">
    <div class="left-box">
      <div class="menu">
        <div
          v-for="(menu, index) in $parent.menuList"
          :key="index"
          class="menu-list"
          :class="{ on: menu.isClick }"
          @click="menuClick(menu)"
        >
          <img :src="menu.img" alt="" />
          <span :class="{ on: menu.isClick }">{{ menu.name }}</span>
        </div>
      </div>
    </div>
    <div class="right-box">
      <div class="filter-box">
        <div class="search-item">
          <div class="title">版本：</div>
          <div class="child-box">
            <p
              v-for="(item, key) in textbookVersion"
              :key="key"
              class="p"
              :class="{ on: item.isClick }"
              @click="setItem(item, textbookVersion)"
            >
              {{ item.editionName }}
            </p>
          </div>
        </div>
        <div class="search-item">
          <div class="title">日期：</div>
          <div>
            <el-date-picker
              v-model="dateValue"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :unlink-panels="true"
              @change="searchList"
            >
            </el-date-picker>
          </div>
          <el-input
            v-model="searchItems.keyword"
            placeholder="请输入内容"
            class="input-with-select"
            @keyup.enter="searchList"
          >
            <i
              slot="suffix"
              class="el-icon-search el-input__icon"
              @click="searchList"
            >
            </i>
          </el-input>
        </div>
      </div>
      <!-- 类别tab -->
      <div class="resource-tab">
        <template v-for="(item, key) in resourceTypeList">
          <div
            v-if="($parent.menuList[0].isClick && item.type) || !item.type"
            :key="key"
            class="tab-item"
            :class="{ active: currentTypeId == item.value }"
            @click="resourceTabClickHandle(item.value)"
          >
            <div class="type-name">{{ item.label }}</div>
          </div>
        </template>
      </div>
      <div class="list-box">
        <div class="list-title"></div>
        <div
          v-for="(item, key) in pageData"
          :key="key"
          class="item-list"
          :class="{ mr40: (key + 1) % 4 != 0 }"
        >
          <!-- 缩略图 -->
          <div class="base-info">
            <div class="thumbnail" @click="toDetail(item.id)">
              <img :src="item.cover" alt="" />
            </div>
            <div class="star">
              <template v-if="item.relationId && item.relationId > 0">
                <i class="el-icon-star-on" @click="cancel(item)"></i>
              </template>
              <template v-else>
                <i class="el-icon-star-off" @click="addList(item)"></i>
              </template>
            </div>
          </div>
          <div class="name" @click="toDetail(item.id)">
            {{ item.editionName }}{{ item.gradeName
            }}{{ getLabelByValue(item.subjectId, subjectOptions)
            }}{{ item.name }}
          </div>
        </div>

        <no-data v-if="pageData.length == 0" message="暂无数据"></no-data>
      </div>
      <div v-if="total != 0" class="list-box-bottom">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="pageList"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  favBooksObj,
  unfavBooksObj,
  getTextbooksVersion,
} from "@/core/api/resource/schoolResource";
import { setStore } from "@/core/util/store";
import {
  updateViewCount,
  getMyTeachingMaterial,
} from "@/core/api/resource/myResource";
import { subjectOptions } from "@/core/util/constdata";

export default {
  name: "TachingPlan",
  props: {
    resourseTypeData: {
      type: Object,
      default: null,
    },
    resourceTypeList: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      CDN: "https://static.wtjy.com/",

      activeName: "first",
      subjectOptionsCopy: [],
      textbookVersion: [], //版本
      currentTypeId: 0, //0-试卷 1-教案 2-教材 3-微课

      interactiveData: this.resourseTypeData,
      currentVersion: 0, //教材版本，全部
      subjectOptions: subjectOptions,
      fullscreenLoading: false,
      // 搜索
      searchItems: {
        pageType: 1, //1-校本教材 2-校本收藏
        level: "",
        subjectId: "",
        editionId: "", //教材版本
        startTime: "",
        endTime: "",
        keyword: "",
        size: 12,
        current: 1,
        rType: 2,
        type: 0,
      },
      // option数据
      pageIndex: 1,
      pageData: [],
      searchYearList: [],
      dateValue: [], //收藏日期
      total: 0,
    };
  },
  computed: {
    ...mapGetters({ getLevelId: "getLevelId", getSubjectId: "getSubjectId" }),
  },
  watch: {
    dateValue() {
      if (this.dateValue && this.dateValue.length > 0) {
        this.searchItems.startTime = this.dateValue[0];
        this.searchItems.endTime = this.dateValue[1];
      }
    },
  },
  created() {
    this.searchItems.subjectId = this.getSubjectId;
    this.searchItems.level = this.getLevelId;
    this.onloadList();
    window.addEventListener("keydown", this.handleKeyDown, true);
  },
  destroyed() {
    window.removeEventListener("keydown", this.handleKeyDown, true);
  },
  methods: {
    resourceTabClickHandle(type) {
      setStore({
        name: "myResourceSearchItems",
        content: { tab_id: type, search_items: this.searchItems },
      });
      this.$parent.changeResourceType(type, this.searchItems, true);
    },
    resetBasePageData() {
      if (this.interactiveData) {
        this.currentTypeId = this.interactiveData.type;
        // this.searchItems = Object.assign(
        //   this.searchItems,
        //   this.interactiveData.searchItems
        // );
      }
      this.dateValue = [this.searchItems.startTime, this.searchItems.endTime];
      if (this.searchItems.editionId > 0) {
        this.textbookVersion.forEach((item) => {
          if (item.editionId == this.searchItems.editionId) {
            item.isClick = true;
          } else {
            item.isClick = false;
          }
        });
      }
      this.getList();
    },
    getLabelByValue(value, data) {
      let label = "";
      data.forEach((item) => {
        if (item.value === value) {
          label = item.label;
        }
      });
      return label;
    },
    onloadList() {
      // 版本
      this.fullscreenLoading = true;
      getTextbooksVersion({
        levelId: this.getLevelId,
        subjectId: this.searchItems.subjectId,
      })
        .then((res) => {
          this.textbookVersion = [
            { editionId: 0, editionName: "全部", isClick: true },
          ];
          let textBooks = res.data.data;
          textBooks.forEach((item) => {
            item.isClick = false;
            this.textbookVersion.push(item);
          });
          this.fullscreenLoading = false;
          this.resetBasePageData();
        })
        .catch(() => {
          this.fullscreenLoading = false;
        });
    },
    //取消收藏
    cancel(val) {
      this.$confirm("确认取消收藏当前教材吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          unfavBooksObj(val.id).then(() => {
            this.getList();
            this.$message.success("取消收藏成功");
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消“取消收藏”操作",
          });
        });
    },
    // 收藏
    addList(val) {
      favBooksObj(val.id).then(() => {
        this.setList(val.id, false);
        this.$message.success("收藏成功");
      });
    },
    //教材版本
    setList(id, type) {
      this.pageData.map((index) => {
        if (id == index.id) {
          if (type) {
            index.relationId = 0;
          } else {
            index.relationId = 1;
          }
        }
      });
    },

    toDetail(id) {
      setStore({
        name: "myResourceSearchItems",
        content: { tab_id: this.currentTypeId, search_items: this.searchItems },
      });
      updateViewCount(id);
      this.$router.push({
        name: "TeachingMaterialDetail",
        query: {
          id: id,
        },
      });
    },

    handleKeyDown(e) {
      let key = null;
      if (window.event === undefined) {
        key = e.keyCode;
      } else {
        key = window.event.keyCode;
      }
      if (key === 13) {
        //触发的事件
        this.searchList();
      }
    },
    pageList(i) {
      this.searchItems.current = i;
      this.getList();
    },
    refreshSearchItemsData() {
      this.searchItems.rType = this.$parent.menuList.filter(
        (item) => item.isClick
      )[0].type;
      this.searchItems.editionId = this.textbookVersion.filter(
        (item) => item.isClick
      )[0].editionId;

      if (this.dateValue && this.dateValue.length > 0) {
        this.searchItems.startTime = this.dateValue[0];
        this.searchItems.endTime = this.dateValue[1];
      } else {
        this.searchItems.startTime = "";
        this.searchItems.endTime = "";
      }
      // this.searchItems.level = this.getLevelId;
      this.searchItems.subjectId = this.getSubjectId;
    },
    getList() {
      this.refreshSearchItemsData();
      getMyTeachingMaterial(this.searchItems)
        .then((res) => {
          if (res.data.data) {
            this.pageData = res.data.data.records;
            this.pageData.forEach((item) => {
              item.cover = this.CDN + "res/book/" + item.id + "/img/1.png";
            });
            this.total = res.data.data.total;
          } else {
            this.pageData = [];
            this.total = 0;
          }
        })
        .catch(() => {
          this.fullscreenLoading = false;
        });
    },
    setItem(val, list) {
      list.map((index) => {
        index.isClick = false;
        if (index.editionId == val.editionId) {
          index.isClick = true;
        }
      });
      this.searchItems.current = 1;
      this.getList();
    },
    searchList() {
      this.searchItems.current = 1;
      this.getList();
    },

    menuClick(menu) {
      if (menu.isClick) {
        // 重复点击已选择的菜单
        return;
      }
      if (menu.type == 4) {
        this.$router.push({ path: "/homework/myHomework" });
        return;
      }
      this.$parent.menuList.map((menuItem) => {
        menuItem.isClick = false;
        if (menu.type === menuItem.type) {
          menuItem.isClick = true;
          this.searchItems.current = 1;
          this.searchItems.pageType = menu.type;
          this.getList();
        }
      });
    },
    viewPaper(examPaperId) {
      this.$router.push({
        name: "viewPaper",
        query: {
          id: examPaperId,
        },
      });
    },
  },
};
</script>
<style lang="scss">
.teaching-material {
  .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    color: $primary-color;
  }
  .el-tabs--border-card > .el-tabs__header .el-tabs__item:hover {
    color: $primary-color;
  }
}
</style>
<style scoped lang="scss">
@import "./public.scss";
.teaching-material {
  width: 1220px;
  margin: 10px auto 50px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .right-box {
    .list-box {
      margin-top: 20px;
      padding: 0 8px;
      display: flex;
      flex-wrap: wrap;

      .item-list {
        margin-bottom: 48px;
        position: relative;
        .el-icon-star-off,
        .el-icon-star-on {
          color: $primary-color;
          font-size: 18px;
          vertical-align: bottom;
          cursor: pointer;
        }
        .base-info {
          height: 260px;
          padding: 14px 9px;
          margin-bottom: 10px;
          border: 1px solid #e0e0e0;
          .thumbnail {
            width: 160px;
            height: 230px;
            background-color: aliceblue;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .star {
            position: absolute;
            right: 4px;
            top: 4px;
          }
        }
        .name {
          text-align: center;
          cursor: pointer;
          max-width: 180px;
        }
      }
      .mr40 {
        margin-right: 40px;
      }
      .none-list {
        width: 100%;
        img {
          width: 400px;
          display: block;
          margin: 20px auto;
        }
        p {
          text-align: center;
          color: #999999;
        }
      }
    }
    .list-box-bottom {
      display: flex;
      justify-content: flex-end;
    }

    .search-item-two {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>
