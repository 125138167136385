<template>
  <div v-loading="fullscreenLoading" class="resource-paper">
    <div class="left-box">
      <!-- <el-button
        type="primary"
        class="btn-all"
        style="width: 246px"
        @click="uploadExamPaperDialog"
        >上传试卷<i class="el-icon-upload el-icon--right"></i
      ></el-button> -->
      <div class="menu">
        <div class="menu-list" @click="uploadExamPaperDialog">
          <img src="@/assets/resourse/paper/icon_up.png" alt="" />
          <span>上传试卷</span>
        </div>
        <div
          v-for="(menu, index) in $parent.menuList"
          :key="index"
          class="menu-list"
          :class="{ on: menu.isClick }"
          @click="menuClick(menu)"
        >
          <img :src="menu.img" alt="" />
          <span>{{ menu.name }}</span>
        </div>
      </div>
    </div>
    <div class="right-box">
      <div class="filter-box">
        <div v-if="searchItems.rType != 2" class="search-item">
          <div class="title">年级：</div>

          <div class="child-box">
            <p
              v-for="(v, i) in gradeOptionsCopy"
              :key="i"
              :class="{ on: v.isClick }"
              @click="setItem(v, gradeOptionsCopy)"
            >
              {{ v.label }}
            </p>
          </div>
        </div>
        <div v-if="searchItems.rType != 2" class="search-item">
          <div class="title">年份：</div>
          <template v-for="(v, i) in searchYearList">
            <p
              :key="i"
              :class="{ on: v.isClick }"
              @click="setItem(v, searchYearList)"
            >
              {{ v.label }}
            </p>
          </template>
        </div>
        <div v-if="searchItems.rType != 2" class="search-item">
          <div class="title">主类：</div>
          <template v-for="(v, i) in sourceTypeItem">
            <template v-if="(v.level && v.level == getLevelId) || !v.level">
              <p
                v-if="
                  (schoolInfo.isImportant == v.value &&
                    $parent.menuList[2].isClick) ||
                  !$parent.menuList[2].isClick ||
                  v.level
                "
                :key="i"
                :class="{ on: v.value == sourceType }"
                @click="checkItem(v)"
              >
                {{ v.name }}
              </p>
            </template>

            <!-- menuList -->
          </template>
        </div>
        <div
          v-if="searchItems.rType != 2 && (sourceType == 0 || sourceType == 1)"
          class="search-item"
        >
          <div class="title">子类：</div>
          <div class="child-box">
            <p
              v-for="(v, i) in examTypeOptionsCopy"
              :key="i"
              :class="{ on: v.isClick }"
              @click="setItem(v, examTypeOptionsCopy)"
            >
              {{ v.label }}
            </p>
          </div>
        </div>
        <div class="search-item">
          <div class="title" style="margin-bottom: 0; line-height: 28px">
            日期：
          </div>
          <el-date-picker
            v-model="dateValue"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :unlink-panels="true"
            @change="searchList"
          >
          </el-date-picker>

          <el-input
            v-model="searchItems.keyword"
            placeholder="请输入内容"
            class="input-with-select"
            @keyup.enter="searchList"
          >
            <i
              slot="suffix"
              class="el-icon-search el-input__icon"
              @click="searchList"
            >
            </i>
          </el-input>
        </div>
      </div>
      <!-- 类别tab -->

      <div class="resource-tab">
        <template v-for="(item, key) in resourceTypeList">
          <div
            v-if="($parent.menuList[0].isClick && item.type) || !item.type"
            :key="key"
            class="tab-item"
            :class="{ active: currentTypeId == item.value }"
            @click="resourceTabClickHandle(item.value)"
          >
            <div class="type-name">{{ item.label }}</div>
          </div>
        </template>
      </div>
      <div class="list-box">
        <div class="list-title"></div>
        <div v-for="(v, i) in pageData" :key="i" class="item-list">
          <div @click="viewPaper(v.id)">
            <div class="base-info">
              <!-- examPaper.filePath && !examPaper.zipPath -->
              <div>
                <span class="title" @click="viewPaper(v.id)"
                  >{{ v.name }}
                  <span v-if="v.filePath" class="tips">试卷已上传</span>
                  <span v-if="v.zipPath" class="tips">资源已上传</span></span
                >
                <i
                  v-if="v.relationType == 1"
                  class="el-icon-star-on"
                  @click.stop="cancel(v)"
                ></i>
              </div>
              <div
                v-if="searchItems.rType > 1"
                class="btn"
                @click.stop="deleteExamPaper(v, searchItems.rType)"
              >
                <i class="el-icon-delete"></i>
              </div>
            </div>
            <div v-if="searchItems.rType == 2" class="my-collect-box">
              <div class="collect-box-left">
                <span>
                  {{ v.typeName }}
                </span>
                <span>|</span>
                <span>{{ v.createTime }}</span>
              </div>
              <!-- <div>
                <el-button
                  type="primary"
                  class="btn-all"
                  @click.stop="downloadExamPaper(v)"
                  >下载<i class="el-icon-download el-icon--right"></i
                ></el-button>
                <el-button
                  type="primary"
                  class="btn-all"
                  @click.stop="analyseExamPaper(v)"
                  >试卷分析<i class="el-icon-finished el-icon--right"></i
                ></el-button>
              </div> -->
            </div>
            <div
              v-if="
                (v.relationType == 1 || v.relationType == 3) &&
                searchItems.rType != 2
              "
              class="my-collect-box"
            >
              <div class="collect-box-left">
                <span>
                  {{ v.resGradeId | setName(gradeOptions, "学段") }}
                  <template v-if="v.year != 0"> {{ v.year }}</template>
                </span>
                <span>|</span>
                <span>浏览{{ v.countView }}次</span>
                <span>|</span>
                <span>{{ v.type | setName(examTypeOptions, "类型") }}</span>
                <span v-if="v.updateTime">|</span>
                <span v-if="v.updateTime">{{
                  v.updateTime.split(" ")[0]
                }}</span>
              </div>
              <div class="btn-list">
                <el-button
                  v-if="searchItems.rType == 3"
                  @click.stop="editExamPaper(v)"
                >
                  编辑
                </el-button>
                <el-button @click.stop="openUpload(v)">上传试卷</el-button>
                <el-button @click.stop="openUploadRes(v)"> 上传资源 </el-button>
                <el-button @click.stop="downloadExamPaper(v)">下载</el-button>
                <!-- <el-button type="primary" class="btn-all"
                >添加自定义<i class="el-icon-collection el-icon--right"></i
              ></el-button> -->
                <el-button @click.stop="analyseExamPaper(v)">
                  试卷分析
                </el-button>
                <!-- <template v-if="v.relationType == 3 && searchItems.rType != 2">
                  <el-button @click.stop="clickDivideExamPaper(v)"
                    >划题标注
                  </el-button>
                </template> -->
              </div>
            </div>
            <div v-if="v.relationType == 2" class="my-group-box">
              <div class="group-box-left">
                <span>{{ v.updateTime }}</span>
                <span>手工组卷</span>
              </div>
              <div class="group-box-right">
                <span class="title">添加自定义</span>
                <span>|</span>
                <span class="title">复制试卷</span>
                <span>|</span>
                <span class="title">修改试卷</span>
                <span>|</span>
                <span class="title" @click.stop="downloadExamPaper(v)"
                  >原卷下载</span
                >
                <span>|</span>
                <span class="title">下载答题卡</span>
                <span>|</span>
                <span class="title">布置练习</span>
                <!-- <span>|</span> -->
              </div>
            </div>
            <!-- <div v-if="v.relationType == 3" class="my-upload-box">

          </div> -->
          </div>
        </div>
        <no-data v-if="pageData.length == 0" message="暂无数据"></no-data>
        <div v-if="total != 0" class="list-box-bottom">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            @current-change="pageList"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!--试卷分析弹窗-->
    <ExamPaperAnalysisForm
      v-if="analysisDialogVisible"
      ref="ExamPaperAnalysisForm"
    ></ExamPaperAnalysisForm>

    <el-dialog
      title="资源上传"
      class="upload-exam-paper-dialog"
      :visible.sync="uploadResDialogVisible"
      width="856px"
    >
      <el-upload
        ref="uploadExamPaper"
        class="upload-button"
        drag
        :action="uploadExamPaperURL"
        :headers="uploadExamPaperHeaders"
        :accept="uploadExamPaperAccept"
        :multiple="uploadExamPaperMultiple"
        :limit="uploadExamPaperLimit"
        :file-list="uploadExamPaperFileListEle"
        :auto-upload="false"
        :on-change="uploadExamPaperOnChange"
        :before-remove="uploadExamPaperBeforeRemove"
        :on-exceed="uploadExamPaperOnExceed"
        :http-request="uploadExamPaperHttpRequest"
      >
        <div class="upload-text">将需要上传文件拖放到此处或<br />点击上传</div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancelButton" @click="uploadResDialogVisible = false"
          >取 消</el-button
        >
        <el-button
          class="confirmButton"
          type="primary"
          :loading="loading"
          @click="submitPaperFile"
          >上 传</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="上传试卷"
      class="upload-exam-paper-dialog"
      :visible.sync="uploadPaperDialogVisible"
      width="856px"
    >
      <div>
        <div class="upload-exam-paper-dialog-header">
          <div
            class="filetype"
            :class="{ 'selected-filetype': uploadExamPaperFileType === 1 }"
            @click="changeUploadExamPaperFileType(1)"
          >
            Word文件上传
          </div>
          <div
            class="filetype"
            :class="{ 'selected-filetype': uploadExamPaperFileType === 2 }"
            @click="changeUploadExamPaperFileType(2)"
          >
            PDF文件上传
          </div>
          <div
            class="filetype"
            :class="{ 'selected-filetype': uploadExamPaperFileType === 3 }"
            @click="changeUploadExamPaperFileType(3)"
          >
            图片文件批量上传
          </div>
          <!-- <div
            class="filetype"
            :class="{ 'selected-filetype': uploadExamPaperFileType === 4 }"
            @click="changeUploadExamPaperFileType(4)"
          >
            其他文件上传
          </div> -->
        </div>
        <el-upload
          ref="uploadExamPaper"
          class="upload-button"
          drag
          :action="uploadExamPaperURL"
          :headers="uploadExamPaperHeaders"
          :accept="uploadExamPaperAccept"
          :multiple="uploadExamPaperMultiple"
          :limit="uploadExamPaperLimit"
          :file-list="uploadExamPaperFileListEle"
          :auto-upload="false"
          :on-change="uploadExamPaperOnChange"
          :before-remove="uploadExamPaperBeforeRemove"
          :on-exceed="uploadExamPaperOnExceed"
          :http-request="uploadExamPaperHttpRequest"
        >
          <div class="upload-text">
            将{{
              uploadExamPaperFileType === 1
                ? " Word "
                : uploadExamPaperFileType === 2
                ? " PDF "
                : uploadExamPaperFileType === 3
                ? "图片"
                : "压缩包"
            }}文件拖放到此处或<br />点击上传
          </div>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="cancelButton"
          @click="uploadPaperDialogVisible = false"
          >取 消</el-button
        >
        <el-button
          class="confirmButton"
          type="primary"
          :loading="loading"
          @click="submitPaperFile"
          >上 传</el-button
        >
      </span>
    </el-dialog>
    <paperForm ref="paperForm"></paperForm>
  </div>
</template>

<script>
import { uploadExamPaper } from "@/core/api/exam/exampaper";
import { mapGetters } from "vuex";
import {
  examTypeOptions,
  levelOptions,
  gradeHighOptions,
  gradeMiddleOptions,
  subjectOptions,
  gradeOptions,
  primaryOptions,
} from "@/core/util/constdata";
import {
  getProvinceList,
  getCityList,
  getDistrictList,
} from "@/core/api/cms/geo";

import { setStore, getStore } from "@/core/util/store";

import {
  reconvert,
  getMyExamPaperPage,
  delMyExamPaperPage,
  getMyGenaratePaper,
  unfavObj,
  favObj,
  delMyUploadPaper,
} from "@/core/api/resource/myResource";
import {
  upload,
  getexampaperzip,
  updateExamPaperCountView,
  editExamPaper,
} from "@/core/api/resource/exampaper";
import { getExamPaper } from "@/core/api/exam/exampaper";
import ExamPaperAnalysisForm from "@/views/resource/form/ExamPaperAnalysisForm";

import paperForm from "./paperForm.vue";
const sourceTypeItem = [
  {
    name: "小考真题",
    value: 11,
    level: 1,
  },
  {
    name: "中考真题",
    value: 12,
    level: 2,
  },
  {
    name: "高考真题",
    value: 13,
    level: 3,
  },
  {
    name: "名校资源",
    value: 1,
  },
  {
    name: "常规资源",
    value: 0,
  },
];
export default {
  name: "Myresource",
  components: {
    ExamPaperAnalysisForm,
    paperForm,
  },
  props: {
    resourseTypeData: {
      type: Object,
      default: null,
    },
    resourceTypeList: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      // 上传试卷
      uploadPaperDialogVisible: false,
      uploadResDialogVisible: false,

      fullscreenLoading: false,
      modelKey: 1,
      listId: 0,
      activeName: "first",
      loading: false, // 载入

      newKeys: 0,
      //tab
      currentTypeId: 0, //0-试卷 1-教案 2-教材 3-微课

      interactiveData: this.resourseTypeData, //tab切换时传输的数据
      // option数据
      sourceTypeItem: sourceTypeItem,
      sourceType: 0,
      examTypeOptions: examTypeOptions,
      gradeHighOptions: gradeHighOptions,
      gradeMiddleOptions: gradeMiddleOptions,
      gradeOptions: gradeOptions,
      primaryOptions: primaryOptions,
      subjectOptions: subjectOptions,
      levelOptions: levelOptions(),
      provinceOptions: [],
      cityOptions: [],
      districtOptions: [],

      showSearch: false,
      // 弹出层
      uploadDialogVisible: false,
      uploadStep: 1, //1-设置属性 2-上传文件
      uploadExamPaperURL: "/resource/exampaperrelation/my/upload",
      uploadExamPaperHeaders: {
        Authorization: "Bearer " + getStore({ name: "access_token" }),
      },
      uploadExamPaperFileType: 1, // 上传试卷类型 1-Word文件 2-PDF文件 3-图片文件批量
      uploadExamPaperFileData: new FormData(),
      uploadExamPaperFileList: [],
      uploadExamPaperAccept: ".docx",
      uploadExamPaperMultiple: false,
      uploadExamPaperLimit: 1,
      uploadExamPaperFileListEle: [],
      ruleForm: {
        name: "",
        level: "",
        resGradeId: "",
        subjectId: "",
        type: "",
        province: "", // 保存的行政区划代码
        city: "", // 保存的行政区划代码
        district: "", // 保存的行政区划代码
        year: "",
        share: 0,
      },
      // ruleForm初始值
      ruleFormReset: {
        name: "",
        level: "",
        resGradeId: "",
        subjectId: "",
        type: "",
        province: "", // 保存的行政区划代码
        city: "", // 保存的行政区划代码
        district: "", // 保存的行政区划代码
        year: "",
        share: 0,
      },

      // 编辑

      editLoading: false,

      // 搜索
      searchItems: {
        level: "",
        resGradeId: 0,
        subjectId: "",
        keyword: "",
        startTime: "",
        endTime: "",
        beforeYear: 0,
        year: 0,
        rType: 1,
        type: 0,
        size: 12,
        current: 1,
      },
      pageIndex: 1,
      pageData: [],
      gradeOptionsCopy: [],
      searchYearList: [],
      examTypeOptionsCopy: [],
      dateValue: [],
      total: 0,
      currentExamPaper: {},
      schoolInfo: {},
      analysisDialogVisible: false, // 试卷分析弹窗,
      CDN: "https://static.wtjy.com/",
    };
  },
  computed: {
    ...mapGetters({ getLevelId: "getLevelId", getSubjectId: "getSubjectId" }),
  },
  watch: {
    dateValue() {
      if (this.dateValue && this.dateValue.length > 0) {
        this.searchItems.startTime = this.dateValue[0];
        this.searchItems.endTime = this.dateValue[1];
      }
    },
  },
  created() {
    this.schoolInfo = getStore({ name: "schoolInfo" });
    if (this.schoolInfo.isImportant == 1) {
      this.sourceType = this.schoolInfo.isImportant;
    }
    this.resetBasePageData();
    this.onloadList();
    this.getList();
    this.getProvinceList();
    this.$nextTick(() => {
      if (this.$route.query.isUpload == 1) {
        this.uploadExamPaperDialog();
      }
    });
    // window.addEventListener("keydown", this.handleKeyDown, true);
  },
  destroyed() {
    // window.removeEventListener("keydown", this.handleKeyDown, true);
  },
  methods: {
    openUpload(examPaper) {
      this.uploadExamPaperFileType = 1;
      this.uploadExamPaperAccept = ".docx";
      this.indexExamPaper = examPaper;
      this.uploadPaperDialogVisible = true;
    },
    openUploadRes(examPaper) {
      this.uploadExamPaperAccept = "";
      this.uploadExamPaperFileType = 4;
      this.indexExamPaper = examPaper;
      this.uploadResDialogVisible = true;
    },
    // 上传文件
    submitPaperFile() {
      this.uploadExamPaperFileData = new FormData();
      this.uploadExamPaperFileData.append("examId", 0);
      this.uploadExamPaperFileData.append("level", this.indexExamPaper.level);
      this.uploadExamPaperFileData.append(
        "subjectId",
        this.indexExamPaper.subjectId
      );
      this.uploadExamPaperFileData.append(
        "examPaperId",
        this.indexExamPaper.id
      );
      this.uploadExamPaperFileData.append(
        "fileType",
        this.uploadExamPaperFileType
      );
      if (this.uploadExamPaperFileList.length > 0) {
        this.$refs.uploadExamPaper.submit(); // 触发 uploadExamPaperHttpRequest 方法，依次在 uploadExamPaperFileData 里增加文件
      } else {
        this.$message.error("请上传试卷文件");
        return;
      }
      this.loading = true;
      // console.log(this.uploadExamPaperFileData);
      // ();
      uploadExamPaper(this.uploadExamPaperFileData)
        .then(() => {
          //触发本地方法  更新当前页面科目
          let editObject = {
            levelId: this.indexExamPaper.level,
            subjectId: this.indexExamPaper.subjectId,
          };
          this.$store.commit("setEditObject", editObject);
          if (this.indexExamPaper.statusReview) {
            this.$message({
              message: "文件上传成功,请重新审核！",
              type: "success",
              duration: 3000,
            });
          } else {
            this.$message.success("上传成功");
          }

          this.uploadPaperDialogVisible = false;
          this.uploadResDialogVisible = false;
          this.loading = false;
          this.initUploadExamPaperDialog();
          this.getList();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    resourceTabClickHandle(type) {
      setStore({
        name: "myResourceSearchItems",
        content: {
          tab_id: type,
          search_items: this.searchItems,
        },
      });
      this.$parent.changeResourceType(type, this.searchItems, true);
    },
    resetBasePageData() {
      if (this.interactiveData) {
        this.currentTypeId = this.interactiveData.type;
        // if(this.interactiveData.searchItems)
        // this.searchItems = Object.assign(
        //   this.searchItems,
        //   this.interactiveData.searchItems
        // );
      }
      this.dateValue = [this.searchItems.startTime, this.searchItems.endTime];
    },
    // handleKeyDown(e) {
    //   let key = null;
    //   if (window.event === undefined) {
    //     key = e.keyCode;
    //   } else {
    //     key = window.event.keyCode;
    //   }
    //   if (key === 13) {
    //     //触发的事件
    //     this.searchList();
    //   }
    // },
    //  addList cancel
    addList(val) {
      favObj(val.id).then(() => {
        if (this.pageData.length == 1 && this.pageIndex > 1) {
          this.pageIndex = this.pageIndex - 1;
        }
        this.getList();
        this.$message.success("收藏成功");
      });
    },
    //  unfavObj favObj
    cancel(val) {
      this.$confirm("确认取消收藏当前试卷吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          unfavObj(val.id).then(() => {
            if (this.pageData.length == 1 && this.pageIndex > 1) {
              this.pageIndex = this.pageIndex - 1;
            }
            this.getList();
            this.$message.success("取消收藏成功");
          });
        })
        .catch(() => {});
    },
    deleteExamPaper(paper, type) {
      this.$confirm("确认删除当前组卷吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deleteMyExamPaper(paper.id, type);
        })
        .catch(() => {});
    },
    deleteMyExamPaper(paperId, type) {
      // type:2-组卷 3-上传
      if (type == 2) {
        delMyExamPaperPage(paperId).then(() => {
          this.$message.success("删除组卷成功");
          if (this.pageData.length == 1 && this.pageIndex > 1) {
            this.pageIndex = this.pageIndex - 1;
          }
          this.getList();
        });
      } else if (type == 3) {
        delMyUploadPaper(paperId).then(() => {
          this.$message.success("删除组卷成功");
          // this.pageIndex = 1;
          if (this.pageData.length == 1 && this.pageIndex > 1) {
            this.pageIndex = this.pageIndex - 1;
          }
          this.getList();
        });
      }
    },
    editExamPaper(examPaper) {
      this.$refs.paperForm.init(examPaper);
    },
    saveExamPaper() {
      let data = Object.assign({}, this.editForm);
      if (this.editForm.level.length > 1) {
        data.level = this.editForm.level == "高中" ? 3 : 2;
      }
      data.province =
        this.convertAddressAjaxFormat(
          this.editForm.province,
          this.provinceOptions
        ) +
        "-" +
        this.editForm.province;
      data.city =
        this.convertAddressAjaxFormat(this.editForm.city, this.cityOptions) +
        "-" +
        this.editForm.city;
      data.district =
        this.convertAddressAjaxFormat(
          this.editForm.district,
          this.districtOptions
        ) +
        "-" +
        this.editForm.district;
      // data.year = this.editForm.year.getFullYear();
      // console.log("save==", this.editForm);
      editExamPaper(data).then((res) => {
        if (res.data.code == 0) {
          this.closeEditDialog();
          this.searchItems.rType = 3;
          this.getList();
        }
      });
    },
    closeEditDialog() {
      this.editDialogVisible = false;
    },
    downloadExamPaper(examPaper) {
      // console.log(examPaper);
      if (!examPaper.filePath && !examPaper.zipPath) {
        this.$message.error("试卷文件不存在！");
        return;
      }
      // 下载试卷增加试卷的浏览数
      updateExamPaperCountView(examPaper.id);
      // if (
      //   examPaper.filePath.endsWith(".doc") ||
      //   examPaper.filePath.endsWith(".docx") ||
      //   examPaper.filePath.endsWith(".pdf")
      // ) {
      //   this.downloadRequest(this.CDN + examPaper.filePath, examPaper.name);
      // } else {
      this.fullscreenLoading = true;
      getexampaperzip(examPaper.id)
        .then((response) => {
          this.downloadRequest(response.data.data.url, response.data.data.name);
          this.fullscreenLoading = false;
        })
        .catch(() => {
          this.fullscreenLoading = false;
        });
      // }
    },
    downloadRequest(url, name) {
      let request = new XMLHttpRequest();
      request.open("GET", url, true);
      request.responseType = "blob";
      request.onload = function () {
        let url = window.URL.createObjectURL(request.response);
        let a = document.createElement("a");
        a.href = url;
        a.download = name;
        a.click();
      };
      request.send();
    },
    pageList(i) {
      this.searchItems.current = i;
      this.getList();
    },
    checkItem(val) {
      this.sourceType = val.value;
      this.searchItems.current = 1;
      this.getList();
    },
    setItem(val, list) {
      list.map((index) => {
        index.isClick = false;
        if (index.value == val.value) {
          index.isClick = true;
        }
      });
      this.searchItems.current = 1;
      this.getList();
    },
    // 初始化一下需要使用的搜索数组
    onloadList() {
      // let arr = JSON.parse(JSON.stringify(this.gradeOptions));
      // gradeHighOptions: gradeHighOptions,
      // gradeMiddleOptions: gradeMiddleOptions,
      // gradeOptions: gradeOptions,
      // primaryOptions: primaryOptions,
      let { schoolPeriod } = getStore({ name: "userInfo" });
      // 搜索用的年级
      this.gradeOptionsCopy.push({ label: "全部", value: 0, isClick: true });
      if (schoolPeriod.indexOf("3") != -1) {
        gradeHighOptions.forEach((i) => {
          i.isClick = false;
          this.gradeOptionsCopy.push(i);
        });
      }
      if (schoolPeriod.indexOf("2") != -1) {
        gradeMiddleOptions.forEach((i) => {
          i.isClick = false;
          this.gradeOptionsCopy.push(i);
        });
      }
      if (schoolPeriod.indexOf("1") != -1) {
        primaryOptions.forEach((i) => {
          i.isClick = false;
          this.gradeOptionsCopy.push(i);
        });
      }

      // 搜索用的年份
      let year = new Date().getFullYear();
      this.searchYearList.push({
        label: "全部",
        value: 0,
        isClick: true,
      });
      for (let i = 0; i < 4; i++) {
        this.searchYearList.push({
          label: year - i,
          value: year - i,
          isClick: false,
        });
      }
      this.searchYearList.push({
        label: "更早",
        value: "001",
        isClick: false,
      });
      //搜索用的类型
      let arrCopy = JSON.parse(JSON.stringify(this.examTypeOptions));
      this.examTypeOptionsCopy.push({
        isClick: true,
        label: "全部",
        value: 0,
      });
      arrCopy.forEach((i) => {
        i.isClick = false;
        this.examTypeOptionsCopy.push(i);
      });
      this.refreahInitData();
    },
    refreahInitData() {
      //年级
      this.gradeOptionsCopy.forEach((item) => {
        item.isClick = false;
        if (item.value == this.searchItems.resGradeId) {
          item.isClick = true;
        }
      });
      //年份
      this.searchYearList.forEach((item) => {
        item.isClick = false;
        if (item.value == this.searchItems.year) {
          item.isClick = true;
        }
      });
      //类型
      this.examTypeOptionsCopy.forEach((item) => {
        item.isClick = false;
        if (item.value == this.searchItems.type) {
          item.isClick = true;
        }
      });
    },
    menuClick(menu) {
      if (menu.isClick) {
        // 重复点击已选择的菜单
        return;
      }
      if (menu.type == 4) {
        this.$router.push({ path: "/homework/myHomework" });
        return;
      }
      setStore({
        name: "myResourceSearchItems",
        content: {
          tab_id: this.currentTypeId,
          search_items: this.searchItems,
        },
      });
      this.$parent.menuList.map((menuItem) => {
        menuItem.isClick = false;
        if (menu.type === menuItem.type) {
          menuItem.isClick = true;
          this.searchItems.current = 1;
          this.getList();
        }
      });
    },
    //获取组卷
    getComposePaper() {
      // this.getAjaxData();
      let obj = {
        startTime: this.searchItems.startTime,
        endTime: this.searchItems.endTime,
        keyword: this.searchItems.keyword,
        size: this.searchItems.size,
        current: this.searchItems.current,
        level: this.getLevelId,
        subjectId: this.getSubjectId,
      };
      getMyGenaratePaper(obj).then((res) => {
        this.pageData = res.data.data.records;
        this.total = res.data.data.total;
      });
    },
    searchList() {
      this.searchItems.current = 1;
      this.getList();
    },
    getAjaxData() {
      this.searchItems.rType = this.$parent.menuList.filter(
        (item) => item.isClick
      )[0].type;
      this.searchItems.resGradeId = this.gradeOptionsCopy.filter(
        (item) => item.isClick
      )[0].value;

      this.searchItems.type = this.examTypeOptionsCopy.filter(
        (item) => item.isClick
      )[0].value;
      this.searchYearList.forEach((i) => {
        if (i.isClick && i.value == "001") {
          this.searchItems.year = 0;
          this.searchItems.beforeYear = this.searchYearList[4].value;
        }
        if (i.isClick && i.value != "001") {
          this.searchItems.year = i.value;
          this.searchItems.beforeYear = 0;
        }
      });
      if (this.dateValue && this.dateValue.length > 0) {
        this.searchItems.startTime = this.dateValue[0];
        this.searchItems.endTime = this.dateValue[1];
      } else {
        this.searchItems.startTime = "";
        this.searchItems.endTime = "";
      }
      // this.searchItems.level = this.getLevelId;
      this.searchItems.subjectId = this.getSubjectId;
    },
    getList() {
      this.getAjaxData();
      if (this.searchItems.rType == 2) {
        this.getComposePaper();
      } else {
        let data = {};
        if (
          this.sourceType == 11 ||
          this.sourceType == 12 ||
          this.sourceType == 13
        ) {
          data.category = this.sourceType;
          data.type = 0;
        } else {
          data.category = this.sourceType;
          data.isImportant = this.sourceType;
        }

        Object.assign(data, this.searchItems);
        getMyExamPaperPage(data).then((res) => {
          this.pageData = res.data.data.records;
          this.total = res.data.data.total;
        });
      }
    },
    searchBtn() {},
    uploadExamPaperDialog() {
      this.$refs.paperForm.init();
    },

    // 获取省列表
    getProvinceList() {
      getProvinceList().then((response) => {
        let provinces = response.data.data;
        if (provinces && provinces.length > 0) {
          this.provinceOptions = [];
          provinces.forEach((item) => {
            this.provinceOptions.push({ value: item.value, label: item.label });
          });
        }
      });
    },
    // 获取市列表
    getCityList() {
      let province = "";
      if (this.editDialogVisible) {
        province = this.editForm.province;
      } else {
        province = this.ruleForm.province;
      }
      getCityList({ province: province }).then((response) => {
        let cities = response.data.data;
        if (cities && cities.length > 0) {
          this.cityOptions = [];
          cities.forEach((item) => {
            this.cityOptions.push({ value: item.value, label: item.label });
          });
        }
      });
    },
    // 获取区列表
    getDistrictList() {
      let city = "";
      if (this.editDialogVisible) {
        city = this.editForm.city;
      } else {
        city = this.ruleForm.city;
      }
      getDistrictList({ city: city }).then((response) => {
        let districts = response.data.data;
        if (districts && districts.length > 0) {
          this.districtOptions = [];
          districts.forEach((item) => {
            this.districtOptions.push({ value: item.value, label: item.label });
          });
        }
      });
    },
    // 修改省事件
    changeProvince() {
      if (this.editDialogVisible) {
        this.editForm.city = "";
        this.editForm.district = "";
      } else {
        this.ruleForm.city = "";
        this.ruleForm.district = "";
      }
      this.getCityList();
    },
    // 修改市事件
    changeCity() {
      if (this.editDialogVisible) {
        this.editForm.district = "";
      } else {
        this.ruleForm.district = "";
      }
      this.getDistrictList();
    },
    gotoUploadStep2() {
      let isValid = true;
      this.$refs.ruleForm.validate((valid) => {
        if (!valid) {
          isValid = false;
        }
      });
      if (!isValid) {
        return;
      }
      this.uploadStep = 2;
    },
    closeUploadDialog() {
      this.initUploadExamPaperDialog();
    },
    initUploadExamPaperDialog() {
      this.ruleForm = this.ruleFormReset;
      this.uploadExamPaperFileType = 1;
      this.uploadExamPaperFileData = new FormData();
      this.uploadExamPaperFileList = [];
      this.uploadExamPaperAccept = ".docx";
      this.uploadExamPaperMultiple = false;
      this.uploadExamPaperLimit = 1;
      this.uploadExamPaperFileListEle = [];
      this.uploadStep = 1;
    },
    changeUploadExamPaperFileType(fileType) {
      this.uploadExamPaperFileType = fileType;
      this.uploadExamPaperFileData = new FormData();
      this.uploadExamPaperFileList = [];
      if (this.uploadExamPaperFileType === 1) {
        this.uploadExamPaperAccept = ".docx";
        this.uploadExamPaperMultiple = false;
        this.uploadExamPaperLimit = 1;
      } else if (this.uploadExamPaperFileType === 2) {
        this.uploadExamPaperAccept = ".pdf";
        this.uploadExamPaperMultiple = false;
        this.uploadExamPaperLimit = 1;
      } else if (this.uploadExamPaperFileType === 3) {
        this.uploadExamPaperAccept = ".jpg,.jpeg,.png,.bmp,.gif";
        this.uploadExamPaperMultiple = true;
        this.uploadExamPaperLimit = 100;
      }
      this.uploadExamPaperFileListEle = [];
    },
    uploadExamPaperOnChange(file, fileList) {
      let existFile = fileList
        .slice(0, fileList.length - 1)
        .find((f) => f.name === file.name);
      if (existFile) {
        this.$message.error("当前文件已经存在！");
        fileList.pop();
      }
      let checkRes = this.checkFile(file);
      if (!checkRes) {
        fileList.pop();
      }
      this.uploadExamPaperFileList = fileList;
    },
    checkFile(file) {
      if (this.uploadExamPaperFileType === 1) {
        if (!file.name.endsWith(".docx")) {
          this.$message.error("只能上传 docx 文件！");
          return false;
        }
      } else if (this.uploadExamPaperFileType === 2) {
        if (!file.name.endsWith(".pdf")) {
          this.$message.error("只能上传 pdf 文件！");
          return false;
        }
      } else if (this.uploadExamPaperFileType === 3) {
        if (
          !file.name.endsWith(".jpg") &&
          !file.name.endsWith(".jpeg") &&
          !file.name.endsWith(".png") &&
          !file.name.endsWith(".bmp") &&
          !file.name.endsWith(".gif")
        ) {
          this.$message.error("只能上传图片文件！");
          return false;
        }
      }
      let is30M = file.size / 1024 / 1024 <= 30;
      if (!is30M) {
        this.$message.error("文件大小不能超过 30 MB！");
        return false;
      }
      return true;
    },
    uploadExamPaperBeforeRemove(file, fileList) {
      this.uploadExamPaperFileList = fileList;
    },
    uploadExamPaperOnExceed() {
      this.$message.warning(
        "最多只能上传 " + this.uploadExamPaperLimit + " 个文件！"
      );
    },
    uploadExamPaperHttpRequest(file) {
      this.uploadExamPaperFileData.append("file", file.file);
    },
    convertAddressAjaxFormat(value, data) {
      let label = "";
      for (let oi = 0; oi < data.length; oi++) {
        let item = data[oi];
        if (item.value === value) {
          label = item.label;
          break;
        }
      }
      return label;
    },
    uploadExamPaper() {
      this.loading = true;
      this.uploadExamPaperFileData = new FormData();
      this.uploadExamPaperFileData.append(
        "name",
        encodeURIComponent(this.ruleForm.name)
      );
      this.uploadExamPaperFileData.append("level", this.ruleForm.level);
      this.uploadExamPaperFileData.append(
        "resGradeId",
        this.ruleForm.resGradeId
      );
      this.uploadExamPaperFileData.append("subjectId", this.ruleForm.subjectId);
      this.uploadExamPaperFileData.append("type", this.ruleForm.type);

      this.uploadExamPaperFileData.append(
        "province",
        encodeURIComponent(
          this.convertAddressAjaxFormat(
            this.ruleForm.province,
            this.provinceOptions
          ) +
            "-" +
            this.ruleForm.province
        )
      );
      this.uploadExamPaperFileData.append(
        "city",
        encodeURIComponent(
          this.convertAddressAjaxFormat(this.ruleForm.city, this.cityOptions) +
            "-" +
            this.ruleForm.city
        )
      );
      this.uploadExamPaperFileData.append(
        "district",
        encodeURIComponent(
          this.convertAddressAjaxFormat(
            this.ruleForm.district,
            this.districtOptions
          ) +
            "-" +
            this.ruleForm.district
        )
      );
      this.uploadExamPaperFileData.append("year", this.ruleForm.year);
      this.uploadExamPaperFileData.append("share", this.ruleForm.share);
      this.uploadExamPaperFileData.append(
        "fileType",
        this.uploadExamPaperFileType
      );

      // eslint-disable-next-line no-unused-vars
      upload(this.uploadExamPaperFileData)
        .then(() => {
          //触发本地方法  更新当前页面科目
          let editObject = {
            levelId: this.ruleForm.level,
            subjectId: this.ruleForm.subjectId,
          };
          this.$store.commit("setEditObject", editObject);
          this.$message.success("创建成功！");
          this.uploadDialogVisible = false;
          this.loading = false;
          this.initUploadExamPaperDialog();
          this.getList();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    clickDivideExamPaper(examPaper) {
      this.currentExamPaper = JSON.parse(JSON.stringify(examPaper));
      if (
        this.currentExamPaper.resultPath &&
        this.currentExamPaper.resultPath.length > 0 &&
        this.currentExamPaper.statusConvert === 1
      ) {
        // console.log("1111");
        this.divideExamPaper();
      } else {
        getExamPaper(this.currentExamPaper.id).then((response) => {
          this.currentExamPaper = response.data.data.examPaper;

          if (!this.currentExamPaper.filePath) {
            this.$message.info("请先上传试卷文件");
            return;
          }
          if (
            this.currentExamPaper.resultPath &&
            this.currentExamPaper.resultPath.length > 0 &&
            this.currentExamPaper.statusConvert === 1
          ) {
            this.divideExamPaper();
          } else {
            if (
              this.currentExamPaper.filePath &&
              this.currentExamPaper.filePath.length > 0 &&
              this.currentExamPaper.statusConvert === 0
            ) {
              this.$message.info("试卷转换中！");
            } else if (
              this.currentExamPaper.filePath &&
              this.currentExamPaper.filePath.length > 0 &&
              this.currentExamPaper.statusConvert === 3
            ) {
              // eslint-disable-next-line no-unused-vars
              reconvert(this.currentExamPaper.id).then((response) => {
                this.$message.info(
                  "试卷转换失败，正在重新进行转换！稍后请重试。"
                );
              });
            }
          }
        });
      }
    },
    divideExamPaper() {
      this.loading = true;
      let filePath = this.currentExamPaper.filePath;
      let filePathArr = filePath.split(",");
      if (filePathArr[0].endsWith(".docx")) {
        // Word 文件
        this.$router.push({
          // name: "resexampaperdivide",
          name: "ExamNewPaperDivide",
          query: {
            id: this.currentExamPaper.id, // 试卷ID
            examId: this.$route.query.id,
          },
        });
      } else if (filePathArr[0].endsWith(".pdf")) {
        // PDF 文件
        this.$router.push({
          path: "/exam/examNewPaperCropper",
          query: {
            id: this.currentExamPaper.id, // 试卷ID
            name: this.currentExamPaper.name,
            subjectId: this.currentExamPaper.subjectId,
          },
        });
      } else {
        // 图片文件
        this.$router.push({
          path: "/exam/examNewPaperCropper",
          query: {
            id: this.currentExamPaper.id, // 试卷ID
            name: this.currentExamPaper.name,
            subjectId: this.currentExamPaper.subjectId,
          },
        });
      }
    },
    analyseExamPaper(examPaper) {
      this.analysisDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.ExamPaperAnalysisForm.init(examPaper.id);
      });
    },
    viewPaper(examPaperId) {
      this.searchItems.paperType = this.searchItems.type;
      setStore({
        name: "myResourceSearchItems",
        content: { tab_id: this.currentTypeId, search_items: this.searchItems },
      });
      if (this.searchItems.rType == 2) {
        this.$router.push({
          name: "PaperPreview",
          query: {
            id: examPaperId,
          },
        });
      } else {
        this.$router.push({
          name: "viewPaper",
          query: {
            id: examPaperId,
          },
        });
      }
    },
  },
};
</script>
<style lang="scss">
.myresource {
  .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    color: $primary-color;
  }
  .el-tabs--border-card > .el-tabs__header .el-tabs__item:hover {
    color: $primary-color;
  }
  .el-dialog {
    max-width: 816px;

    .el-dialog__body {
      .form-item-box {
        // border-top: 1px solid #e0e0e0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .checkbox-item {
          width: 100%;
          // padding-left: 100px;
          margin-bottom: 8px;
        }
        .el-date-editor.el-input {
          width: 100%;
        }
        .item-box-btn {
          display: flex;
          justify-content: space-between;
          p {
            color: #2196f3;
            font-size: 14px;
            margin-right: 8px;
            margin-left: 8px;
            cursor: pointer;
            &:last-child {
              margin-right: 0;
            }
          }
        }
        .el-select {
          width: 100%;
        }
        .el-form-item {
          width: 100%;
        }
      }
      .two-box {
        .el-form-item {
          width: 49%;
        }
      }
      .el-form-item {
        padding: 8px 0;
        margin-bottom: 0;
      }
    }
    .el-form-item__label {
      // text-align: left;
      margin-bottom: 0;
      white-space: nowrap;
    }
    // .el-input {
    //   input {
    //     background-color: #f5f5f5;
    //     border-color: #f5f5f5;
    //   }
    // }
  }
  // .el-select .el-input.is-focus .el-input__inner {
  //   border-color: #f5f5f5;
  // }
  // .el-select .el-input__inner:focus {
  //   border-color: #f5f5f5;
  // }
  // .el-select .el-input__inner:hover {
  //   border-color: #f5f5f5;
  // }
  // .el-icon-arrow-up:before {
  //   content: "\e78f";
  // }
  .el-select .el-input .el-select__caret {
    color: rgba(0, 0, 0, 0.5);
  }
  .secrch-item {
    width: 176px;
    margin-bottom: 16px;
    input {
      // background-color: #f5f5f5;
      border-color: #f5f5f5;
    }
  }
  .upload-button .el-upload .el-upload-dragger {
    width: 752px;
  }
}
</style>

<style scoped lang="scss">
@import "./public.scss";
.resource-paper {
  width: 1220px;
  margin: 10px auto 50px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .btn-list {
    // display: flex;
    // justify-content: flex-end;
    // .el-button {
    //   // margin-bottom: 8px;
    //   padding: 4px 8px !important;
    // }
  }

  .left {
    float: left;
  }
  .right {
    float: right;
  }
  .clear-both {
    clear: both;
  }

  .right-box {
    .list-box {
      margin-top: 20px;
      .none-list {
        img {
          width: 400px;
          display: block;
          margin: 20px auto;
        }
        p {
          text-align: center;
          color: #999999;
        }
      }
    }

    .list-box-bottom {
      display: flex;
      justify-content: flex-end;
    }

    .search-item-old {
      justify-content: space-between;
      padding: 0 14px;
    }
    .item-list {
      padding: 24px 20px;
      border: 1px solid #e0e0e0;
      margin-bottom: 12px;
      cursor: pointer;
      .el-icon-star-off,
      .el-icon-star-on {
        color: $primary-color;
        font-size: 18px;
        vertical-align: bottom;
        cursor: pointer;
      }
      .base-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .tips {
          padding: 2px 6px;
          background: #f3f8ff;
          margin-left: 6px;
          border-radius: 4px;
          color: #2474ed;
          font-size: 12px;
        }
        .btn {
          cursor: pointer;
          // border-radius: 100%;
          // background-color: rgba(255, 0, 0, 0.5);
          // color: #ffffff;
        }
        .title {
          cursor: pointer;
          margin-right: 10px;
        }
      }
      .my-collect-box {
        margin-top: 12px;
        // display: flex;
        // justify-content: space-between;
        .collect-box-left {
          flex-shrink: 0;
          margin-bottom: 12px;

          span {
            color: rgba(0, 0, 0, 0.5);
            margin-right: 8px;
          }
        }
        .btn-all {
          padding: 4px 8px;
        }
      }
      .my-group-box {
        margin-top: 27px;
        display: flex;
        justify-content: space-between;
        .group-box-left {
          span {
            margin-right: 8px;
            color: rgba(0, 0, 0, 0.5);
          }
        }
        .group-box-right {
          span {
            color: rgba(0, 0, 0, 0.5);
            margin-right: 8px;
          }
          .title {
            cursor: pointer;
            color: $primary-color;
          }
        }
      }
    }
  }

  .upload-exam-paper-dialog {
    .upload-exam-paper-dialog-header {
      height: 30px;
      .filetype {
        display: inline-block;
        margin-right: 20px;
        cursor: pointer;
      }
      .selected-filetype {
        color: #2474ed;
      }
    }
    .upload-button {
      width: 752px;
      .upload-text {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .edit-exam-paper-dialog {
  }
}
</style>
