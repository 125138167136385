<template>
  <div v-loading="fullscreenLoading" class="resource-paper">
    <div class="left-box">
      <div class="menu">
        <div
          v-for="(menu, index) in $parent.menuList"
          :key="index"
          class="menu-list"
          :class="{ on: menu.isClick }"
          @click="menuClick(menu)"
        >
          <img :src="menu.img" alt="" />
          <span :class="{ on: menu.isClick }">{{ menu.name }}</span>
        </div>
      </div>
    </div>
    <div class="right-box">
      <div class="filter-box">
        <div class="search-item">
          <div class="title">题型：</div>
          <div class="child-box">
            <p
              v-for="(v, i) in questionList"
              :key="i"
              :class="{ on: v.isClick }"
              @click="setItem(v, questionList)"
            >
              {{ v.label }}
            </p>
          </div>
        </div>
        <div class="search-item filter-item">
          <div class="filter-left">
            <div class="title">难度：</div>
            <template v-for="(v, i) in difficultyList">
              <p
                :key="i"
                :class="{ on: v.isClick }"
                @click="setItem(v, difficultyList)"
              >
                {{ v.label }}
              </p>
            </template>
          </div>
        </div>
        <div class="search-item">
          <div class="title">题类：</div>
          <template v-for="(v, i) in strQuuestionItem">
            <p
              :key="i"
              :class="{ on: v.isClick }"
              @click="setItem(v, strQuuestionItem)"
            >
              {{ v.label }}
            </p>
          </template>
        </div>
        <div class="search-item filter-item">
          <div class="filter-left">
            <div class="title">年份：</div>
            <template v-for="(v, i) in searchYearList">
              <p
                :key="i"
                :class="{ on: v.isClick }"
                @click="setItem(v, searchYearList)"
              >
                {{ v.label }}
              </p>
            </template>
          </div>
        </div>

        <div class="search-item">
          <div class="title">来源：</div>
          <div class="child-box">
            <p
              v-for="(v, i) in questionSourceItem"
              :key="i"
              :class="{ on: v.isClick }"
              @click="setItem(v, questionSourceItem, checkType)"
            >
              {{ v.label }}
            </p>
          </div>
          <div class="check-type">
            <el-checkbox
              v-model="checkType"
              @change="setListDefault('questionSourceItem')"
              >来源多选</el-checkbox
            >
          </div>
        </div>

        <div class="search-item">
          <div class="title">日期：</div>
          <div>
            <el-date-picker
              v-model="dateValue"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :unlink-panels="true"
              @change="searchList"
            >
            </el-date-picker>
          </div>
          <el-input
            v-model="searchItems.keyword"
            placeholder="请输入内容"
            class="input-with-select"
            @keyup.enter="searchList"
          >
            <i
              slot="suffix"
              class="el-icon-search el-input__icon"
              @click="searchList"
            >
            </i>
          </el-input>
        </div>
      </div>
      <!-- 类别tab -->
      <div class="resource-tab">
        <template v-for="(item, key) in resourceTypeList">
          <div
            v-if="($parent.menuList[0].isClick && item.type) || !item.type"
            :key="key"
            class="tab-item"
            :class="{ active: currentTypeId == item.value }"
            @click="resourceTabClickHandle(item.value)"
          >
            <div class="type-name">{{ item.label }}</div>
          </div>
        </template>
      </div>
      <div v-if="!loading" class="question-box">
        <div v-for="(v, i) in examquestionData" :key="i" class="question-list">
          <div class="question-id">试题ID：{{ v.id }}</div>
          <div style="padding: 0 12px 12px" v-html="v.question"></div>
          <div class="btm-box">
            <div class="tips">
              <span> 更新:{{ v.createTime | setTimeStyle }}</span>
              <!-- <span>{{ v.typeName }}</span> -->
              <span>难度:{{ v.difficulty }} </span>
            </div>

            <div class="btn-box">
              <span :class="{ succ: v.isRelation }" @click="relation(v)">
                <template v-if="v.isRelation">已</template>收藏</span
              >
              <span @click="show(v)">解析</span>
              <span @click="situation(v)">考情</span>
              <span @click="similarity(v)">同类题</span>
              <el-button
                v-if="planQuestionList.indexOf(v.id) == -1"
                type="primary"
                class="btn-all"
                @click="exampaperplanquestion(v)"
              >
                加入试题篮
                <i class="el-icon-plus el-icon--right"></i>
              </el-button>
              <el-button
                v-else
                type="info"
                @click="exampaperplanquestionDel(v)"
              >
                移除试题篮
                <i class="el-icon-minus el-icon--right"></i>
              </el-button>
            </div>
          </div>
          <!-- 考情 -->
          <div v-if="situationArr.indexOf(v.id) != -1" class="situation-box">
            <template
              v-if="v.situation && v.situation.anyExamUseQuestion.length == 0"
            >
              数据尚未同步
            </template>
            <template v-else>
              <p>
                <span class="situation-box-name">考情：</span>本题共
                <span>{{ v.situation.countReply }}</span> 人使用,共{{
                  v.situation.examNum
                }}场考试
              </p>
              <div
                v-for="(m, n) in v.situation.anyExamUseQuestion"
                :key="n"
                class="situation-list"
              >
                <div class="situation-list-left">
                  <p>{{ m.examName }}</p>
                  <div>
                    <span class="situation-list-name">{{ m.examDate }}</span>
                    <span> | </span>
                    <span class="situation-list-name"
                      >共{{ m.countReply }}人参考</span
                    >
                    <span> | </span>
                    <span class="situation-list-name">{{ m.schoolLevel }}</span>
                  </div>
                </div>
                <div class="situation-list-right">
                  {{ m.scoreRate | setNumber }}
                </div>
              </div>
            </template>
          </div>
          <div v-if="showListArr.indexOf(v.id) != -1" class="analysis-box">
            <div v-if="v.newQuestion" class="new-question-box">
              <p v-if="v.newQuestion" class="question-tips">
                当前试题没有找到答案解析，已推荐同类题
              </p>
              <div class="analysis-item">
                【同类题】
                <div v-html="v.newQuestion"></div>
              </div>
              <el-button
                v-if="planQuestionList.indexOf(v.newId) == -1"
                type="primary"
                class="btn-all"
                @click="exampaperplanquestion(v, 1)"
              >
                加入试题篮
                <i class="el-icon-plus el-icon--right"></i>
              </el-button>
              <el-button
                v-else
                type="info"
                @click="exampaperplanquestionDel(v, 1)"
              >
                移除试题篮
                <i class="el-icon-minus el-icon--right"></i>
              </el-button>
            </div>

            <div
              v-if="v.knowledgeNames && v.knowledgeNames.split('#').length > 0"
              class="know-box analysis-item"
            >
              【知识点】
              <div v-for="(m, n) in v.knowledgeNames.split('#')" :key="n">
                {{ m }}
              </div>
            </div>
            <div v-if="v.answer && v.answer.length > 0" class="analysis-item">
              【答案】
              <div v-html="v.answer"></div>
            </div>
            <div
              v-if="v.analysis && v.analysis.length > 0"
              class="analysis-item"
            >
              【解答】
              <div v-html="v.analysis"></div>
            </div>
            <div v-if="v.analyse && v.analyse.length > 0" class="analysis-item">
              【分析】
              <div v-html="v.analyse"></div>
            </div>
            <div v-if="v.ability && v.ability.length > 0" class="analysis-item">
              【点评】
              <div v-html="v.ability"></div>
            </div>
            <!-- <div class="id-box">学科id：{{ v.xuekeId }}</div> -->
          </div>
        </div>
      </div>
      <no-data v-if="examquestionData.length == 0" message="暂无数据"></no-data>

      <el-pagination
        v-if="(examquestionData.length > 0 || current > 1) && !loading"
        background
        layout="prev, pager, next"
        :total="total"
        :current-page="current"
        @current-change="pageList"
      >
      </el-pagination>
    </div>
    <testBasket
      :key="componentsKey"
      :plan-id="planId"
      @setQuestionList="setQuestionList"
      @setPlanid="setPlanid"
      @getNewList="searchList"
    ></testBasket>
    <similarQuestion ref="similarQuestion"></similarQuestion>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getquestiontype } from "@/core/api/resource/breakdownPaper";
import {
  questionType,
  examTypeOptions,
  difficultyList,
  difficultyListCopy,
} from "@/core/util/constdata";
import { myPage } from "@/core/api/resource/myResource";
import {
  // examquestionSearch,
  answer,
  situation,
  examquestionrelationMy,
  examquestionrelationDel,
  exampaperplanquestion,
  exampaperplanquestionDel,
  resourceList,
} from "@/core/api/resource/knowledgePointsPaper";
import testBasket from "@/views/resource/knowledgePointsPaper/components/testBasket.vue";
import similarQuestion from "@/components/resource/similarQuestion.vue";
import { setStore } from "@/core/util/store";
// const resourceTypeList = ["试卷", "教案", "教材", "微课"];

export default {
  name: "Myresource",
  components: { testBasket, similarQuestion },
  props: {
    resourseTypeData: {
      type: Object,
      default: null,
    },
    resourceTypeList: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      //tab
      currentTypeId: 0, //0-试卷 1-教案 2-教材 3-微课
      current: 1,
      total: 1,
      planId: 0,
      searchItems: {},
      questionSourceItem: [],
      fullscreenLoading: false,
      checkType: false,
      searchYearList: [],
      showListArr: [],
      situationArr: [],
      questionList: [],
      examquestionData: [],
      difficultyList: difficultyList,
      questionType: questionType,
      examTypeOptions: examTypeOptions,
      difficultyListCopy: difficultyListCopy,
      dateValue: [],
      question: "",
      planQuestionList: [],
      componentsKey: 123,
    };
  },
  computed: {
    ...mapGetters({ getLevelId: "getLevelId", getSubjectId: "getSubjectId" }),
  },

  created() {
    this.initItem();
    this.setSearchList();
    this.setListDefault();
    // this.getList();
  },
  destroyed() {},
  methods: {
    similarity(val) {
      this.$refs.similarQuestion.init(val);
    },
    setQuestionList(val) {
      // console.log(val);
      this.planQuestionList = val;
    },
    //设置planid
    setPlanid(planId) {
      this.planId = planId;
    },
    pageList(i) {
      this.current = i;
      this.getList();
    },
    // 收藏
    relation(v) {
      let data = {
        examQuestionId: v.id,
        type: 1,
      };
      if (v.isRelation == 1) {
        this.$confirm("确认取消收藏当前试题吗", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            examquestionrelationDel(data).then(() => {
              v.isRelation = 0;
              this.$message({
                message: "已取消收藏",
                type: "success",
              });
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消“取消收藏”操作",
            });
          });
      } else {
        examquestionrelationMy(data).then(() => {
          // console.log();
          this.$message({
            message: "已收藏",
            type: "success",
          });
          v.isRelation = 1;
        });
      }
    },
    //获取考情
    situation(v) {
      if (v.situation) {
        if (this.situationArr.indexOf(v.id) == -1) {
          this.situationArr.push(v.id);
        } else {
          this.situationArr.splice(this.situationArr.indexOf(v.id), 1);
        }
        return;
      }
      // console.log();
      situation(v.id).then((res) => {
        v.situation = res.data.data;
        if (this.situationArr.indexOf(v.id) == -1) {
          this.situationArr.push(v.id);
        } else {
          this.situationArr.splice(this.situationArr.indexOf(v.id), 1);
        }
      });
    },
    // 切换是否显示页面列表
    show(v) {
      if (this.showListArr.indexOf(v.id) != -1) {
        this.showListArr.splice(this.showListArr.indexOf(v.id), 1);
        return;
      }
      answer(v.id).then((res) => {
        // console.log(res);
        if (res.data.data.id) {
          v.answer = res.data.data.answer;
          v.analyse = res.data.data.analyse;
          v.analysis = res.data.data.analysis;
          v.ability = res.data.data.ability;
          v.knowledgeNames = res.data.data.knowledgeNames;
          if (res.data.data.id != v.id) {
            v.newQuestion = res.data.data.question;
            // v.knowledgeNames = res.data.data.knowledgeNames;
            v.newId = res.data.data.id;
            v.newXuekeId = res.data.data.xuekeId;
          }
          if (this.showListArr.indexOf(v.id) == -1) {
            this.showListArr.push(v.id);
          } else {
            this.showListArr.splice(this.showListArr.indexOf(v.id), 1);
          }
        } else {
          this.$message({
            message: "未找到同类题和解析",
            type: "warning",
          });
        }
      });
    },
    searchList() {
      this.current = 1;
      this.getList();
    },
    // 恢复默认值
    setListDefault() {
      // val
      if (!this.checkType) {
        this.questionSourceItem = this.questionSourceItem.map((item, index) => {
          item.isClick = false;
          if (index == 0) item.isClick = true;
          return item;
        });

        this.getList();
      }
    },
    // 切换
    setItem(val, list, type) {
      list.map((index) => {
        if (!type) {
          index.isClick = false;
        }

        if (index.value == val.value) {
          index.isClick = !index.isClick;
        }
      });
      this.current = 1;
      //判断下如果存在搜索条件 就将排序方式改为综合
      if (this.question != "" && this.sortTypeList[0].name == list[0].name) {
        this.sortTypeList[0].isClick = true;
        this.sortTypeList[1].isClick = false;
        this.sortTypeList[2].isClick = false;
        return;
      }
      this.getList();
    },
    resourceTabClickHandle(type) {
      setStore({
        name: "myResourceSearchItems",
        content: { tab_id: type, search_items: this.searchItems },
      });

      this.$parent.changeResourceType(type, this.searchItems, true);
    },
    setSearchList() {
      // 搜索用的年份
      let year = new Date().getFullYear();
      this.searchYearList.push({
        label: "全部",
        value: -1,
        isClick: true,
      });
      for (let i = 0; i < 4; i++) {
        this.searchYearList.push({
          label: year - i,
          value: year - i,
          isClick: false,
        });
      }
      this.searchYearList.push({
        label: "更早",
        value: -2,
        isClick: false,
      });
      let arr = [
        {
          value: "0-1",
          label: "全部",
          isClick: true,
        },
      ];
      //难度列表循环
      this.difficultyList.map((item) => {
        item.isClick = false;
      });
      this.difficultyList = arr.concat(this.difficultyList);
      this.difficultyList = JSON.parse(JSON.stringify(this.difficultyList));
      // 通过科目、学段查询题型
      this.questionList = [];
      this.questionList.push({ label: "全部", value: -1, isClick: true });
      getquestiontype({
        levelId: this.getLevelId,
        subjectId: this.getSubjectId,
      }).then((res) => {
        res.data.data.forEach((index) => {
          this.questionList.push({
            value: index.name,
            label: index.name,
            isClick: false,
          });
        });
      });

      this.examTypeOptions.map((item) => {
        item.isClick = false;
      });
      this.examTypeOptions = [
        { label: "全部", value: -1, isClick: true },
      ].concat(this.examTypeOptions);
      this.examTypeOptions = JSON.parse(JSON.stringify(this.examTypeOptions));
    },
    getResourceList() {
      let data = {
        levelId: this.$parent.getLevelId,
        subjectId: this.$parent.getSubjectId,
      };
      resourceList(data).then((res) => {
        //         this.questionSourceItem
        let array = res.data.data.map((item) => {
          item.value = item.id;
          item.label = item.name;
          item.isClick = false;
          return item;
        });
        this.questionSourceItem = [
          {
            value: 0,
            label: "全部",
            isClick: true,
          },
        ].concat(array);
      });
    },
    initItem() {
      this.getResourceList();
      let strQuuestion = "全部 常考题 易错题 好题 压轴题";
      let strQuuestionArr = strQuuestion.split(" ");
      this.strQuuestionItem = strQuuestionArr.map((item, index) => {
        return {
          label: item,
          value: index,
          isClick: index == 0 ? true : false,
        };
      });
    },
    onValue(list) {
      let value = [];
      list.forEach((index) => {
        if (index.isClick) {
          value.push(index.value);
        }
      });
      return value.join(",");
    },
    getList() {
      let data = {
        size: 10,
        current: this.current,
        level: this.getLevelId,
        subjectId: this.getSubjectId,
        // planId: this.planId,
        question: this.question,
        type: 1,
      };
      Object.assign(data, this.searchItems);
      data.rType = this.$parent.menuList.filter((item) => item.isClick)[0].type;
      this.situationArr = [];
      this.showListArr = [];
      data.isSingle = this.treeType ? 0 : 1;
      // if (this.treeType) {
      data.isUnion = this.knowType ? 0 : 1;
      // }
      if (!this.treeType) {
        data.isUnion = 1;
      }
      if (this.dateValue && this.dateValue.length > 0) {
        data.startTime = this.dateValue[0];
        data.endTime = this.dateValue[1];
      } else {
        data.startTime = "";
        data.endTime = "";
      }
      // data.noImg = this.noImg ? 0 : 1;
      // data.hasAnswer = this.hasAnswer ? 0 : 1;
      // treeType
      // knowType
      //题型
      data.typeName = this.onValue(this.questionList);
      // 难度
      data.difficulty = this.onValue(this.difficultyList);
      // 年份
      data.year = this.onValue(this.searchYearList);
      // 考试类型
      data.examType = this.onValue(this.examTypeOptions);
      // 来源

      data.questionSource = this.onValue(this.questionSourceItem);

      this.loading = true;
      myPage(data)
        .then((res) => {
          this.loading = false;
          this.examquestionData = res.data.data.records.map((item) => {
            if (item.source) {
              item.question = item.question.replace(
                ">",
                `><span class='jyw-question-tips'>${item.source}</span>`
              );
            }

            return item;
          });

          this.total = res.data.data.total;

          this.showListArr = [];
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 移除试题篮
    exampaperplanquestionDel(val, type) {
      let data = {
        examPaperPlanId: this.planId,
        questionId: type ? val.newId : val.id,
        xuekeId: type ? val.newXuekeId : val.xuekeId,
      };
      exampaperplanquestionDel(data).then(() => {
        // console.log(res);
        this.$message({
          message: "移除成功！",
          type: "success",
        });
        // 调用 ，更新列表
        // v.isPlan = 0;
        this.componentsKey = new Date().getTime();
      });
    },
    //添加到试题篮
    exampaperplanquestion(val, type) {
      let data = {
        planId: this.planId,
        questionId: type ? val.newId : val.id,
        xuekeId: type ? val.newXuekeId : val.xuekeId,
      };

      exampaperplanquestion(data).then(() => {
        // console.log(res);
        this.$message({
          message: "加入成功",
          type: "success",
        });
        // val.isPlan = 1;
        this.componentsKey = new Date().getTime();
      });
    },
    menuClick(menu) {
      if (menu.isClick) {
        // 重复点击已选择的菜单
        return;
      }
      // console.log(menu);
      if (menu.type == 4) {
        this.$router.push({ path: "/homework/myHomework" });
        return;
      }
      if (this.currentTypeId == 0) {
        this.$parent.menuList.map((menuItem) => {
          menuItem.isClick = false;
          if (menu.type === menuItem.type) {
            menuItem.isClick = true;
          }
        });
        this.resourceTabClickHandle(1);
        setStore({
          name: "myResourceSearchItems",
          content: {
            tab_id: 1,
            search_items: this.searchItems,
          },
        });
        return;
      }
      setStore({
        name: "myResourceSearchItems",
        content: {
          tab_id: this.currentTypeId,
          search_items: this.searchItems,
        },
      });
      this.$parent.menuList.map((menuItem) => {
        menuItem.isClick = false;
        if (menu.type === menuItem.type) {
          menuItem.isClick = true;
          this.searchItems.current = 1;
          this.getList();
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/sass/qusetion.scss";
@import "./public.scss";
.resource-paper {
  width: 1220px;
  margin: 10px auto 50px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .btn-list {
    .el-button {
      // margin-bottom: 8px;
      padding: 4px 8px !important;
    }
  }

  .left {
    float: left;
  }
  .right {
    float: right;
  }
  .clear-both {
    clear: both;
  }

  .filter-item {
    justify-content: space-between;
    .filter-left {
      display: flex;
      flex-flow: wrap;
      align-items: center;
      .el-select {
        width: 120px;
        margin-right: 8px;
      }
    }
    .filter-right {
      display: flex;
      flex-flow: wrap;
      align-items: center;

      .input-class {
        width: 50px;
      }
    }
  }
}
</style>
